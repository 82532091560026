import { Box, Skeleton } from '@mui/material';
const ProfileStatusSkeleton = () => {
  return (
    <Box className="mt2 displayFlex alignItemsCenter">
      <Skeleton variant="circular" height={30} width={30} className="mr1" />
      <Skeleton variant="text" className="fontSize1rem width50" />
    </Box>
  );
};

export default ProfileStatusSkeleton;
