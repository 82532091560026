import { Typography, Box, Button } from '@mui/material';
import CustomDialog from '../../Custom Components/CustomDialog/CustomDialog';
import { labels } from '../../../data/constants';
import { useNavigate } from 'react-router-dom';
const ProfileCompleteModal = ({ showDialog, close }) => {
  const navigate = useNavigate();
  return (
    <CustomDialog handleOpen={showDialog} showClose={close}>
      <Typography textAlign={'center'} fontWeight={'bold'}>
        {labels.profileCompleteModal.title}
      </Typography>
      <Typography textAlign={'center'} mt={2}>
        {labels.profileCompleteModal.dialogMsg}
      </Typography>
      <Box textAlign={'center'} mt={3}>
        <Button
          variant="buttonVariant1"
          onClick={() => {
            navigate('/profile');
          }}
        >
          {labels.profileCompleteModal.btnLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};
export default ProfileCompleteModal;
