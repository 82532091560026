import React, { useEffect, useState } from 'react';
import EmailForm from '../../Components/LoginAndSignup/EmailForm';
import OtpForm from '../../Components/LoginAndSignup/OtpForm';
import { Container } from 'react-bootstrap';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SignupUser } from '../../Services/SignupService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import { setUserDetails } from '../../redux/login';
const SignupForm = () => {
  const [showOtpPanel, setShowOtpPanel] = useState(false);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { user} = useAuth();
   const [disableButton, setDisableButton] = useState(false);
   const [counter, setCounter] = useState(3);
  const [loginData, setLoginData] = useState({ emailAddress: '', otp: '' });
  useEffect(() => {
    if (user) {
        dispatch(setUserDetails(user))
        navigate('/dashboard', { replace: true });
           }
}, [user, dispatch, navigate]);
  //To verify provided email and request for otp.
  const verifyEmailAndGetOtp = async (values) => {
    try {
      const mainValues = {
              email: values?.emailAddress.toLowerCase(),
      }
      setDisableButton(true);
      let response = await dispatch(SignupUser(mainValues)); 
           if (response === true) {
        
        setLoginData((prevState) => ({
          ...prevState,
          emailAddress: values.emailAddress,
        }));
        setShowOtpPanel(true);
       
         }
         }
         catch (e) {
      console.log(e);
    }
    setDisableButton(true);
};
React.useEffect(() => {
  if (disableButton) {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setDisableButton(false);
      setCounter(3)
    }
  }
}, [counter, disableButton]);
  //To hide otp panel and reset email form.
  const resetAndHide = () => {
    setLoginData({ emailAddress: '', otp: '' });
    setShowOtpPanel(false);
  };

  return (
    <div className="main-login-page ">
      <Container fluid className="login-div">
        <Grid container justifyContent={'center'}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            justifyContent={'center'}
            mt={7}
            px={4}
          >
            {!showOtpPanel && (
              <EmailForm
                formTitle="Signup"
                submitHandler={verifyEmailAndGetOtp}
                initialVal={loginData.emailAddress}
                actionType={'signup'}
                disableStatus={disableButton}
                            />
            )}
            {showOtpPanel && (
              <OtpForm loginData={loginData} onBack={resetAndHide} />
            )}
            <Typography
              mt={3}
              color={'gray'}
              fontSize={14}
              textAlign={'center'}
            >
              By Signing Up, I agree to the Pathways Terms of Service.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SignupForm;
