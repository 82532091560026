import MainLayout from '../Layouts/MainLayout';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <MainLayout>
      <LoginForm />
    </MainLayout>
  );
};

export default Login;
