import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  InputLabel,
  IconButton,
  Tooltip,
  Grid,
} from '@mui/material';
import { labels } from '../../data/constants';
import CreateIcon from '@mui/icons-material/Create';
import { useEffect, useState } from 'react';
const FinancialProjection = ({
  questionDetails,
  handleBlur = () => {},
  handleDataChange = () => {},
  isDisabled = false,
}) => {
  const financialYearsLength = 3;
  const financialFields = [
    { key: 'gross_revenue', label: 'Revenue' },
    { key: 'cogs', label: 'COGS' },
    { key: 'gross_profit', label: 'Gross Profit' },
    { key: 'gross_margin', label: 'Gross Margin' },
    { key: 'op_expenses', label: 'Operating Expenses' },
    { key: 'op_profit', label: 'Operating Profit' },
    { key: 'capex', label: 'Capital Expenditures' },
    { key: 'investment_income', label: 'Investment Income' },
    { key: 'ending_cash', label: 'Ending Cash Balance' },
    { key: 'team_members', label: 'Team Members' },
  ];
  const [financialData, setFinancialData] = useState(null);
  const [financialYears, setFinancialYears] = useState([]);
  useEffect(() => {
    if (questionDetails?.answer) {
      let parsedAnswerData = JSON.parse(questionDetails?.answer);

      //Fetching financial years.
      let financialYearsArray = [];
      parsedAnswerData?.financials?.forEach((item) => {
        financialYearsArray.push(item['year']);
      });
      setFinancialYears(financialYearsArray);
      setFinancialData(parsedAnswerData);
    } else {
      //Creating financial years array.
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let financialYearArray = [];
      for (let i = 0; i < financialYearsLength; i++) {
        financialYearArray.push(currentYear + (i + 1));
      }
      //Creating default financial data structure.
      let defaultStructure = {
        financials: [],
        financials_description: '',
      };
      for (let j = 0; j < financialYearsLength; j++) {
        defaultStructure.financials.push({
          ...financialFields,
          year: financialYearArray[j],
        });
      }
      //Setting financial years
      setFinancialYears(financialYearArray);
      //setting financial data
      setFinancialData(defaultStructure);
    }
  }, [questionDetails]);

  const calculateGrossMargin = (dataSet, finacialYear, field, value) => {
    let dependentFields = {
      gross_revenue: dataSet?.financials[finacialYear]['gross_revenue'],
      cogs: dataSet?.financials[finacialYear]['cogs'],
      gross_profit: dataSet?.financials[finacialYear]['gross_profit'],
      gross_margin: dataSet?.financials[finacialYear]['gross_margin'],
    };
    dependentFields[field] = value;
    if (
      dependentFields.gross_revenue &&
      dependentFields.cogs &&
      dependentFields.gross_profit
    ) {
      let grossMargin =
        ((dependentFields.gross_profit - dependentFields.cogs) /
          dependentFields.gross_profit) *
        100;
      return Math.round((grossMargin + Number.EPSILON) * 100) / 100;
    } else {
      return null;
    }
  };

  const handleChange = (block, indexPosition, fieldName, value) => {
    let dataCopy = JSON.parse(JSON.stringify(financialData));
    if (block === 'description') {
      dataCopy.financials_description = value;
      setFinancialData(dataCopy);
      handleDataChange(questionDetails?.id, JSON.stringify(dataCopy));
    } else if (
      fieldName === 'gross_revenue' ||
      fieldName === 'cogs' ||
      fieldName === 'gross_profit'
    ) {
      let grossMargin = calculateGrossMargin(
        dataCopy,
        indexPosition,
        fieldName,
        value
      );
      if (grossMargin !== null) {
        dataCopy.financials[indexPosition][fieldName] = value;
        dataCopy.financials[indexPosition]['gross_margin'] = grossMargin;
        setFinancialData(dataCopy);
        handleDataChange(questionDetails?.id, JSON.stringify(dataCopy));
      } else {
        dataCopy.financials[indexPosition][fieldName] = value;
        setFinancialData(dataCopy);
        handleDataChange(questionDetails?.id, JSON.stringify(dataCopy));
      }
    } else {
      dataCopy.financials[indexPosition][fieldName] = value;
      setFinancialData(dataCopy);
      handleDataChange(questionDetails?.id, JSON.stringify(dataCopy));
    }
  };
  const handleSubmit = () => {
    handleBlur(questionDetails?.id, JSON.stringify(financialData));
  };
  return (
    <>
      <Box className="displayFlex alignItemsCenter mt1">
        <InputLabel
          sx={{ overflow: 'visible' }}
          shrink={false}
          className="dashboardLabel1"
        >
          <Typography variant="customStyle1">
            {questionDetails?.question}
          </Typography>
        </InputLabel>
        {questionDetails?.aiAutofill && (
          <Tooltip
            title={
              questionDetails?.isDependent
                ? questionDetails?.dependentText
                : questionDetails?.answer?.trim()
                ? 'Delete existing Text to use Ai Auto Fill'
                : 'AI Autofill'
            }
          >
            <IconButton
              onClick={() => {
                // handleAiCall(tabItem?.id, tabItem?.question, tabItem?.answer);
              }}
              className="ml1 iconButtonType4"
              // disabled={
              //   questionLoader.forQuestion === tabItem?.id ||
              //   isDisabled === true
              // }
            >
              {/* {questionLoader.loading &&
              questionLoader.forQuestion === tabItem?.id ? (
                <CircularProgress
                  className="mr05"
                  sx={{ color: 'rgb(255, 255, 255)' }}
                  size={'1rem'}
                />
              ) : (
                <CreateIcon fontSize="small" />
              )} */}
              {labels.ideaDetails.aiAutoFill}
            </IconButton>
          </Tooltip>
        )}
        {questionDetails?.autoFillPro && (
          <Typography className="ml05" variant="customStyle2">
            {labels.ideaDetails.pro}
          </Typography>
        )}
      </Box>

      <Box className="border1 borderColorGrey borderRadius4 mt2 padding1">
        <Grid container>
          <Grid item xs={12} md={8}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="borderNone">
                    <TableCell></TableCell>
                    {financialYears.length !== 0 &&
                      financialYears?.map((year) => (
                        <TableCell className="centeredCell">
                          <Typography variant="tableHeading">{year}</Typography>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {financialFields.map((field) => (
                    <TableRow className="borderNone">
                      <TableCell>
                        <Typography variant="dashboardCompHeading5">
                          {field.label}
                        </Typography>
                      </TableCell>
                      {field.key !== 'gross_margin'
                        ? financialYears.map((item, index) => (
                            <TableCell>
                              <Box className="displayFlex alignItemsCenter">
                                <Typography
                                  variant="dashboardCompHeading5"
                                  className="mr05"
                                >
                                  {field.key !== 'team_members' && '$'}
                                </Typography>
                                <Box className="flex1">
                                  <TextField
                                    value={
                                      financialData?.financials[index][
                                        field.key
                                      ] || ''
                                    }
                                    type="number"
                                    onChange={(e) => {
                                      handleChange(
                                        'financials',
                                        index,
                                        field.key,
                                        e.target.value
                                      );
                                    }}
                                    onBlur={handleSubmit}
                                    disabled={isDisabled}
                                    fullWidth
                                    className="textFieldType4 mt0 mb0"
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          ))
                        : financialYears.map((item, index) => (
                            <TableCell>
                              <Typography className="textAlignRight">
                                {financialData?.financials[index][field.key]}
                                {'%'}
                              </Typography>
                            </TableCell>
                          ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <InputLabel
          sx={{ overflow: 'visible' }}
          shrink={false}
          className="dashboardLabel1 mt1"
        >
          <Typography className="displayFlex alignItemsCenter">
            Key drivers and Reasoning
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          placeholder={questionDetails?.placeholder}
          className="textFieldType4"
          multiline={true}
          minRows={3}
          value={financialData?.financials_description || ''}
          disabled={isDisabled}
          onChange={(e) => {
            handleChange('description', '', '', e.target.value);
          }}
          onBlur={handleSubmit}
          draggable={false}
          onDragStart={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
        />
      </Box>
    </>
  );
};
export default FinancialProjection;
