export const STATUS_200 = 200;
export const STATUS_300 = 300;
export const STATUS_400 = 400;
export const STATUS_401 = 401;
export const STATUS_403 = 403;
export const SEARCH_LIMIT = 50;
export const DATA_LIMIT = 20;
export const profileScore_25 = 25;
export const profileScore_50 = 50;
export const profileScore_75 = 75;
export const likeValue = 1;
export const dislikeValue = 2;
export const noLikeDislikeValue = 0;
export const variantOnValue = 'true';
export const variantOffValue = 'false';
export const Authentication = {
  REDIRECT_LOGIN: 'redirectToLogin',
  TOKEN: 'token',
  USER_INFO: 'userInfo',
  TIME: 'getTime',
};

export const toastMsg = {
  userLogged: `User logged in successfully.`,
  userFailed: `User logged in failed.`,
  userLogout: `User logged out successfully.`,
  regComplete: `Congratulations! Your registration has been completed successfully.`,
  accountDoesNotExist: `Account with this email does not exist.`,
  downloadFail: `Failed to download file.`,
  tokenExpire: 'Token expireded.',
  checkEmail: `Please check your email to verify.`,
  errorMssg: `Some error occurred.`,
  enterOtp: `Please enter OTP `,
};
export const profileMsg = {
  fillSection: `  Fill out at least 50% of each section for the best results, but
    don't worry about hitting 100%. Focus on the fields where you have
    the most passion and thoughts. Our AI uses this info to generate
    your custom business ideas, determine founder-idea fit, and create
    other tailored guidance for you.`,
};
export const profileLabels = {
  selectOption: 'Please select your options',
};

export const labels = {
  commonLabels: {
    cancelLabel: 'Cancel',
    submitLabel: 'Submit',
  },
  headers: {
    invite: 'Invite',
    userMenu: {
      account: 'Account',
      workSpace: 'Workspace',
      helpFaq: 'Help and FAQ',
      logOut: 'Logout',
    },
  },
  quickIdeas: {
    heading: 'Top 5 Pathways - For You',
    detailButton: 'Details',
  },
  profileStatus: {
    heading: 'Profile Status',
    content1:
      'Add more profile data so our AI can generate custom ideas and tailored guidance for you. Filling in your profile helps you think through important considerations that will impact your journey as an entrepreneur.',
    content2:
      'If you add more profile data our AI will generate smarter ideas and better tailored guidance for you. Filling in more of your profile can also help you think through important considerations that will impact your journey as an entrepreneur',
    content3:
      'Your profile looks good. If you add even more data to it, our AI will create ideas and guidance that match you even better.',
    content4:
      'Wow! Your profile looks great!. Our AI is using it to create highly-customized ideas and guidance specific to who you are and your goals.',
    editButton: 'Edit Profile',
  },
  profile: {
    title: 'Profile',
    resumeBtn: 'Download Resume',
    resumeBtnDisabled:
      'Please complete your profile at least 50 percent to access this option.',
    tipLabel1: 'Tip',
    completeProfileDialogTitle: 'Please complete your basic details first',
    completeProfileDialogMsg:
      'You have not completed your profile in account. Please complete it first then come again.',
    goToAccount: 'Go to account',
    successTipMsg1: 'After updating your profile, go to',
    successTipMsg2:
      ' to see your AI-generated ideas. The data you entered auto-saves.',
  },
  profileCompleteModal: {
    title: 'Your profile is incomplete!',
    dialogMsg:
      'Please complete atleast 50 percent of your profile then come again.',
    btnLabel: 'Go to profile',
  },
  setupStatus: {
    heading: '6 Steps To Success',
  },
  welcome: {
    heading: 'Welcome to Pathways!',
    subHeading1: 'Fill out some of your ',
    subHeading2: ' to get started, then click "Refresh Ideas" on the ',
    subHeading3: ' page.',
  },
  accounts: {
    title: 'Account',
    generalTabLabel: 'General',
    planTab: 'Plan and Payments',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    countryLabel: 'Country',
    roleLabel: 'Role',
    saveBtnLabel: 'Save',
    emailLabel: 'Email',
    editEmailLabel: 'Edit email',
    cancelBtnLabel: 'Cancel',
    planLabel: 'Plan',
    upgradeLabel: 'Upgrade',
    cancelSubscription: 'Cancel Subscription',
    proCredit: 'Pro Credits',
    included: 'Included:',
    used: 'Used: ',
    payments: 'Payments',
    paymentHistory: 'Payment History',
    proLabel: 'PRO',
    selectPlanHeading: 'Select a plan',
    learnMore: 'Learn More',
    upgradeNow: 'Upgrade Now',
    selectPlan:
      'Select a plan and upgrade now to get expanded access to all the powerful Pro features.',
    accountsOtpModal: {
      otpHeading: 'Enter OTP',
      otpSentLabel: 'An OTP has been sent to',
      enterOtpLabel: 'Please enter the OTP to update to your email address.',
      cancelBtnLabel: 'Cancel',
      resendBtnLabel: 'Resend OTP',
    },
    successModal: {
      emailChangeSuccess: 'Email changed successfully!',
      userMsg:
        'Your email has been changed successfully. Please re-login with the new email.',
      btnLabel: 'Okay',
    },
    roleChangeModal: {
      title: 'Role Change',
      subHeading: 'Warning!',
      paraText:
        'Role change will result in complete removal of your current profile information. Do you wish to proceed?',
      agreeBtnLabel: 'Yes',
      disagreeBtnLabel: 'No',
    },
  },
  businessIdeas: {
    title: 'Business Ideas',
    addIdeaModal: {
      title: 'Add Manual Idea',
      infoText:
        "Note, manually-created ideas are locked by default. If you later don't want the idea you can unlock it. You can even thumbs-down it if you want to tell the AI to avoid ideas like it.",
      nameLabel: 'Name',
      summaryLabel: 'Summary',
      namePlaceholder: 'Idea Name',
      summaryPlaceholder: 'Briefly describe your idea in 1-2 sentences',
    },
    businessIdeasContainer: {
      detailButton: 'Details >',
      edtReasearchButton: 'Edit in Research >',
      shareButton: 'Share',
      badgeLabel1: 'Manual',
      undoBtn: 'Click to undo',
      ideaDislikedMsg: 'Idea rejected. Will be replaced upon refresh.',
    },
    businessIdeasHeader: {
      addIdea: 'Add Idea',
      refreshIdeas: 'Refresh Ideas',
      generatingIdeas: 'Generating Ideas...',
      helpText1:
        '= Prevent an idea from being hidden by future idea refreshes. Refreshes happen when you update your profile or take actions here like creating variants.',
      helpText2:
        '= Tell our AI that you like an idea. This will encourage it to produce similar or tangential ideas.',
      helpText3:
        '= Tell our AI that you dislike an idea. This will help it know what you want to avoid.',
      helpText4: '= Create variations of the idea.',
    },
    feedbackModal: {
      title: 'Your Reason',
      likePlaceholder: 'Reason you like this idea',
      dislikePlaceholder: "Reason you don't like this idea",
      noteTitle: 'Note',
      noteText:
        'This idea will be hidden but it will still be used in the background to teach our AI your preferences.',
      NoteLockText:
        "This idea is locked so it won't be removed from your current ideas. Unlock it to have it replaced with a new idea on the next refresh.",
      submitBtnLabel: 'Submit',
    },
  },
  businessPlan: {
    title: 'Business Plan',
    selectAnIdea: 'Please select an Idea to continue.',
    subTitle: 'Complete and export a business plan for your idea',
    exportBtnLabel: 'Export and Download',
    samplePlanLabel: 'Download sample exported plan',
    ideaSelector: {
      title: 'Idea',
      subTitle: 'Pick an idea to work with',
    },
    syncedWithIdea: 'synchornized with idea details',
  },
  filter: {
    title: 'Filter',
    topFiveTitle: 'Top 5',
    consideringTitle: 'Considering',
    notRightTitle: 'Not Quite Right',
    tableHeaders: {
      rankTitle: 'Rank',
      shoutTitle: 'Shout',
      fitGradeTitle: 'A.I. Fit Grades',
    },
    tooltips: {
      rankTooltip:
        'Your preference for what idea(s) you most want to consider executing on',
      shoutTooltip:
        'How publicly passionate, or "shouting excited", can you get about this?',
    },
    gradingPanelLabels: {
      para1:
        'Our AI graded your fit with this idea based on each section of your profile',
      label1: 'Passions and Fun:',
      label2: 'The World:',
      label3: 'Goals:',
      label4: 'Experience:',
      label5: 'Overall:',
    },
  },
  pro: {
    title: 'Pro',
  },
  supportEmailID: 'contact@yourpathway.ai',
  research: {
    title: 'Research',
  },
  ideaDetails: {
    returnButtonLabel: '< Business Ideas',
    pageTitle: 'Idea Details',
    shareButtonLabel: 'Share',
    nameFieldLabel: 'Name',
    summaryFieldLabel: 'Summary',
    aiAutoFill: 'AI Autofill',
    pro: 'PRO',
    shareIdeaLabel: 'Share Idea',
    disableModifyLabel: 'Modify Or Disable',
    copyLinkLabel: 'Copy Link',
    emailLabel: 'Email',
    tweetLabel: 'Tweet',
    shareFacebook: 'Share on Facebook',
    shareLinkedIn: 'Share on LinkedIn',
    shareModal: {
      title: 'Modify or Disable Idea Share',
      subTitle: 'Disable/Enable Idea Share',
      warning:
        'Warning, this will immediately disable all links that were previously shared for this idea.',
      enableShareLabel: 'Enable Share',
      disableShareLabel: 'Disable Share',
      changeShareLevelLabel: 'Change Detail Level',
      ideaSummaryLabel: 'Idea Summary',
      ideaDetailedLabel: 'Detailed Idea',
      cancelBtnLabel: 'Cancel',
      updateBtnLabel: 'Update',
    },
    sharePage: {
      learnMoreLabel: 'Learn More',
      learnMorePara:
        'To learn more about Pathways and collaborate with AI to generate your own business ideas for free, visit ',
      noIdeaText: 'Sorry! No details found for the searched idea.',
    },
    tabs: {
      general: {
        tabLabel: 'General',
        descriptionLabel: 'Description',
        howItWorksLabel: 'How It Works',
        keyInnovationsLabel: 'Key Innovations',
        competitiveDifferenceLabel: 'Competitive Differentiation',
        systemTypeLabel: 'System Type (if Software)',
        myNoteLabel: 'My Notes',
      },
      customers: {
        tabLabel: 'Customers',
        lctLabel: 'Launch Customer Type(s)',
        fctLabel: 'Future Customer Type(s)',
        pdmLabel: 'Purchase Decision Maker',
        boLabel: 'Budget Owner',
        icpLabel: 'Ideal Customer Profile',
        lcpLabel: 'Launch Customer Problem',
        lcbLabel: 'Launch Customer Benefits',
      },
      goToMarket: {
        tabLabel: 'Go-To-Market',
        suggestionTip: {
          title:
            'Think about how you will acquire customers and be profitable. Use logical estimates. Definitions:',
          suggestionPoints: {
            point1:
              'Customer Acquisition Cost (CAC) = total marketing and sales costs to acquire each new customer',
            point2:
              'Deal Value: Total revenue received from an average new customer. Use for non-recurring offerings.',
            point3:
              'Annual Contract Value (ACV): Total revenue received from an average new customer each year. Use for recurring offerings.',
          },
        },
        pcamLabel: 'Primary Customer Acquisition Method(s)',
        scamLabel: 'Secondary Customer Acquisition Method(s)',
        cacLabel: 'Customer Acquisition Cost (CAC)',
        acvLabel: 'Deal or Annual Contract Value (ACV)',
        marketLabel: 'Market',
        editResearchBtnLabel: 'Edit in Research >',
      },
    },
    systemTypeTooltipData: {
      heading:
        'If your solution is software, select the system type based on these definitions:',
      point1:
        'System of Record: The source of truth for data. E.g. CRM, ERP, Email server',
      point2:
        'System of Engagement: Where the user does the work/workflow, but not the system of record. E.g. a tool like Outreach that writes data into a CRM, or an email client',
      point3:
        'System of Decision: Analysis or decision- making tools. E.g. PowerBI, Tableau',
    },
  },
  workspace: {
    title: 'Workspace',
    editButtonLabel: 'Edit Name',
    deleteButtonLabel: 'Delete Workspace',
    createButtonLabel: 'Create Workspace',
    inviteButtonLabel: 'Send Invite',
    inviteLabel: 'Invite a Collaborator',
    collaborator: 'Collaborators',
    commenterLabel: '',
    editorLabel: '',
    commenterText: 'Can view and make comments',
    editorText: 'Can edit all except your account and profile',
    existingCollaborator: 'Existing Collaborators',
    deleteNoteText:
      'Delete this workspace, any customized profile data, all ideas, and any other data unique to this workspace.',
    notDeleteMainWorkspace:
      'Note, you cannot delete your main workspace here.Contact ',
    createNoteText:
      'You can create an additional workspace with a unique profile, ideas, markets, and competitors.',
    ownerLabel: 'Owner',
    updateWorkspaceLabel: 'Update Workspace',
    workspaceNameInputLabel: 'Workspace name',
    cancelButtonLabel: 'Cancel',
    deleteConfirmLabel:
      'Are you sure you want to delete your this workspace? You will permanently lose access to the data unique to this workspace.',
    deleteWorkspacePopupHeading: 'Delete this Workspace',
    guest: {
      inviteCollabMessage1:
        'You must be the workspace owner to invite or edit collaborators.',
      inviteCollabMessage2:
        'Switch to a workspace you own, or ask the workspace owner to invite collaborators.',
    },
  },
  careerPathways: {
    title: 'Your Career Pathways',
    generatePathways: 'Generate Pathways',
    regeneratePathways: 'Regenerate Pathways',
    noDataFoundLabel: 'Please click on Generate Pathways.',
    guestAccessDeniedLabel:
      'Access to Career Pathways in guest workspace is denied.',
  },
};

export const errors = {
  login: {
    otpBlank: 'OTP cannot be left blank!',
    invalidOtp: 'Invalid OTP!',
    otpSize: 'OTP cannot be less than six characters',
    validEmail: 'Please enter valid email address',
    emailRequired: 'Please enter your email address.',
  },
  accounts: {
    emailNotChanged: 'Email address not changed!',
    emailNotCorrect: 'Email address is not correct!',
    incorrectFirstName: 'Invalid first name!',
    incorrectLastName: 'Invalid last name!',
    invalidLength: 'Cannot be more than 40 characters',
    invalidMinLength: 'Cannot be less than 2 characters',
    invalidCountry: 'Please select a country!',
    selectARole: 'Please select a role!',
    alreadyActiveRole: 'This is your current role, please select another role!',
  },
  businessIdeas: {
    addIdeaModal: {
      ideaName: 'Idea name cannot be left blank!',
      ideaSummary: 'Idea summary cannot be left blank!',
    },
  },
  ideaDetails: {
    ideaNameBlank: 'Idea name cannot be left blank!',
    ideaSummaryBlank: 'Idea summary cannot be left blank!',
  },
};
export const warnings = {
  accounts: {
    note: 'Please Note!',
    emailChange:
      'Please verify your new email address. You will receive a one-time password (OTP) shortly.',
    confirmText: 'Do you want to continue with the change?',
    confirmBtnText: 'Yes',
    cancelBtnText: 'No',
  },
};

export const placeholders = {
  accounts: {
    selectRole: 'Please select a role',
    selectCountry: 'Please select a country',
  },
};
export const regexes = {
  nameValidation: /^[A-Za-z\u0000-\u007F\u0080-\u00FF\u0100-\u017F\s'-.]+$/imu,
  emailValidation:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
};

export const limitations = {
  nameMaxLimitation: 40,
  nameMinLimitation: 2,
};
