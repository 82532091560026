import { useState } from 'react';
import { Box, Typography, Button, Divider, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  labels,
  likeValue,
  dislikeValue,
  variantOnValue,
} from '../../data/constants';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import IosShareIcon from '@mui/icons-material/IosShare';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import IdeaShareMenu from '../CommonComponents/IdeaShareMenu/IdeaShareMenu';
import IdeaShareControlModal from '../CommonComponents/IdeaShareModal/IdeaShareControlModal';
const BusinessIdeaComponent = ({
  ideaOption,
  handleIdeaLock = () => {},
  handleChange = () => {},
  handleIdeaUndo = () => {},
  handleVariantOption = () => {},
  handleIdeaShare = () => {},
  isChild = false,
  compKey,
  dividerPosition,
  isGuest = false,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showShareModal, setShowShareModal] = useState(false);
  const handleShareUpdate = async (ideaId, shareStatus, shareLevel) => {
    const response = await handleIdeaShare(ideaId, shareStatus, shareLevel);
    if (response) {
      setShowShareModal(false);
    } else {
      setShowShareModal(false);
    }
  };
  return (
    <Box key={compKey}>
      {dividerPosition === 'top' && <Divider sx={{ marginTop: 1 }} />}
      {ideaOption?.locked === 'false' &&
      ideaOption?.likedislike === dislikeValue ? (
        <Box mt={1}>
          <Typography variant="dashboardCompHeading5" className="colorGrey">
            {ideaOption?.name}
          </Typography>
          <Typography variant="paraText1" className="textCenter">
            {labels.businessIdeas.businessIdeasContainer.ideaDislikedMsg}
            <Button
              disabled={isGuest}
              onClick={() => {
                handleIdeaUndo(ideaOption?.id);
              }}
              variant="textButton2"
            >
              {labels.businessIdeas.businessIdeasContainer.undoBtn}
            </Button>
          </Typography>
        </Box>
      ) : (
        <Box className="displayFlex">
          {isChild && (
            <Box className="displayFlex alignItemsCenter justifyCenter mr05">
              <SubdirectoryArrowRightIcon />
            </Box>
          )}
          <Box className="flex1">
            <Box
              display={'flex'}
              mt={1}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant="dashboardCompHeading5">
                  {ideaOption?.name}
                </Typography>
                <Button
                  variant="textButton2"
                  onClick={() => {
                    navigate(`${ideaOption.id}`);
                  }}
                >
                  {labels.businessIdeas.businessIdeasContainer.detailButton}
                </Button>
              </Box>
              <Box>
                {ideaOption?.type === 'Manual' ? (
                  <Typography variant="badgeText1">
                    {labels.businessIdeas.businessIdeasContainer.badgeLabel1}
                  </Typography>
                ) : (
                  ''
                )}
                <IconButton
                  disabled={isGuest}
                  onClick={() => {
                    handleIdeaLock(ideaOption?.id, ideaOption?.locked);
                  }}
                  className="iconButtonType1"
                >
                  {ideaOption?.locked === 'true' ? (
                    <LockIcon fontSize="medium" />
                  ) : (
                    <LockOpenOutlinedIcon fontSize="medium" />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleChange(
                      likeValue,
                      ideaOption,
                      ideaOption?.likedislike
                    );
                  }}
                  className="iconButtonType1"
                  disabled={isGuest}
                >
                  {ideaOption?.likedislike === likeValue ? (
                    <ThumbUpIcon fontSize="medium" />
                  ) : (
                    <ThumbUpAltOutlinedIcon fontSize="medium" />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleChange(
                      dislikeValue,
                      ideaOption,
                      ideaOption?.likedislike
                    );
                  }}
                  className="iconButtonType1"
                  disabled={isGuest}
                >
                  {ideaOption?.likedislike === dislikeValue ? (
                    <ThumbDownIcon fontSize="medium" />
                  ) : (
                    <ThumbDownAltOutlinedIcon fontSize="medium" />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleVariantOption(ideaOption?.id, ideaOption?.variants);
                  }}
                  disabled={isGuest}
                  className={
                    ideaOption?.variants === variantOnValue
                      ? 'iconButtonType1 backgroundGrey'
                      : 'iconButtonType1'
                  }
                >
                  <AltRouteIcon fontSize="medium" />
                </IconButton>
                <IconButton
                  onClick={handleClick}
                  className="iconButtonType1 ml1"
                >
                  <IosShareIcon className="mr05" fontSize="medium" />
                  {labels.businessIdeas.businessIdeasContainer.shareButton}
                </IconButton>
                <IdeaShareMenu
                  ideaDetail={ideaOption}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleShareOpen={() => {
                    setShowShareModal(true);
                  }}
                  open={open}
                />
              </Box>
            </Box>
            <Typography variant="paraText1">
              {ideaOption.description}
            </Typography>
          </Box>
        </Box>
      )}

      {dividerPosition === 'bottom' && <Divider sx={{ marginTop: 1 }} />}
      {showShareModal && (
        <IdeaShareControlModal
          hanldleModalOpen={showShareModal}
          handleModalClose={() => {
            setShowShareModal(false);
          }}
          ideaDetails={{
            ideadId: ideaOption?.id,
            share: ideaOption?.share,
            detailLevel: ideaOption?.ideadetail,
          }}
          handleShareModification={handleShareUpdate}
        />
      )}
    </Box>
  );
};
export default BusinessIdeaComponent;
