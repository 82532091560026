import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { errorMessage } from '../lib/helper';
import { setLoading } from '../redux/login';

export const LoginUser = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await axios.post(`${ApiConfig.login}`, payload);
        if (
          response?.data?.status >= STATUS_200 &&
          response?.data?.status < STATUS_300
        ) {
          toast.success(response?.data?.message + response?.data?.data);
          dispatch(setLoading(false));
          return true;
        } else {
          toast.error(response?.data?.message);
                    throw response;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
        return false;
      }
    };
  }
};
