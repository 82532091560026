import DashboardLayout from '../Layouts/DashboardLayout';
import ResearchComp from './ResearchComp';
const ResearchContainer = () => {
  return (
    <DashboardLayout>
      <ResearchComp />
    </DashboardLayout>
  );
};
export default ResearchContainer;
