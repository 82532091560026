import { getObjectFromStore } from '../storage/Storage';
import { toast } from 'react-toastify';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { toastMsg } from '../data/constants';
// TO REDUCE TIME OFFSET FOR TIME DIFF.
function adjustForTimezone(date) {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date;
}
export const configureUrl = (url) => {
  let authUrl = url;
  if (url && url[url.length - 1] === '/') {
    authUrl = url.substring(0, url.length - 1);
  }
  return authUrl;
};
export const getFileNameFromUrl = (url) => {
  // eslint-disable-next-line
  return url.replace(/^.*[\\\/]/, '');
};
export const getSelectImageName = (state, prefix) => {
  const key = prefix;
  const unique = [...new Map(state.map((item) => [item[key], item])).values()];
  return unique;
};
export const isEmpty = (text) => {
  return !(text.toString().trim().length > 0 && text.toString().trim() !== '0');
};
export function formatDate(d, format = 'dd/mm/yy', adjustOffset = false) {
  if (!d) {
    return '';
  }
  let monthsArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let date = new Date(d);
  if (adjustOffset) {
    date = adjustForTimezone(new Date(d));
  }
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  var h = date.getHours();
  var m = date.getMinutes();
  var timeFormat = 'AM';
  var s = date.getSeconds();
  var ms = date.getMilliseconds();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  //Time
  if (h <= 11 && m < 60) {
    timeFormat = 'AM';
  } else if (h >= 12) {
    timeFormat = 'PM';
  }
  if (format.includes('12')) {
    h = h % 12;
    h = h ? h : 12; // the hour '0' should be '12'
    // m = m;
  }
  if (h < 10) {
    h = '0' + h;
  }
  if (m < 10) {
    m = '0' + m;
  }
  if (s < 10) {
    s = '0' + s;
  }
  if (ms < 10) {
    ms = '0' + ms;
  }

  let formatNeed = format?.toLowerCase();

  if (formatNeed === 'dd-mm-yy') {
    return (d = dd + '/' + mm + '/' + yyyy?.toString()?.slice(2, 4));
  } else if (formatNeed === 'dd/mm/yy') {
    return (d = dd + '/' + mm + '/' + yyyy?.toString()?.slice(2, 4));
  } else if (formatNeed === 'dd/mm/yyyy') {
    return (d = dd + '/' + mm + '/' + yyyy);
  } else if (formatNeed === 'dd/mm/yyyy') {
    return (d = dd + '/' + mm + '/' + yyyy);
  } else if (formatNeed === 'dd-mm-yyyy') {
    return (d = dd + '-' + mm + '-' + yyyy);
  } else if (formatNeed === 'yyyy-mm-dd hh:mm:ss.ms') {
    return (d =
      yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + m + ':' + s + '.' + ms);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm:ss.ms') {
    return (d =
      dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s + '.' + ms);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm:ss') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ':' + s);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm') {
    return (d = dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m);
  } else if (formatNeed === 'dd-mm-yyyy hh:mm 12h') {
    return (d =
      dd + '-' + mm + '-' + yyyy + ' ' + h + ':' + m + ' ' + timeFormat);
  } else if (formatNeed === 'yyyy-mm-dd') {
    return (d = yyyy + '-' + mm + '-' + dd);
  } else if (formatNeed === 'yyyy-mm-ddthh:mm') {
    return (d = yyyy + '-' + mm + '-' + dd + 'T' + h + ':' + m);
  } else if (formatNeed === 'mon dd yyyy') {
    return (d = monthsArr[mm - 1] + ' ' + dd + ' ' + yyyy);
  } else if (formatNeed === 'iso') {
    return date?.toISOString();
  } else if (formatNeed === 'utc') {
    return date?.toUTCString();
  }
}

export const roundOffValue = (data) => {
  try {
    return Math.round(Number(data));
  } catch (e) {
    return data;
  }
};
export const isValiEmail = (val) => {
  // eslint-disable-next-line
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regEmail.test(val)) {
    return true;
  }
};

export const errorMessage = (response) => {
  try {
    return (
      response?.response?.data?.data ||
      response?.data?.response?.data ||
      response?.data?.data ||
      response?.data?.message ||
      response?.message ||
      response?.data ||
      response?.statusText ||
      'Something went wrong'
    );
  } catch (e) {
    return e?.data || 'Something went wrong';
  }
};

export const deleteApiPayload = (value) => {
  try {
    return {
      data: value,
    };
  } catch (e) {
    return {
      data: {},
    };
  }
};

export const handleFileReader = async (event, setFile) => {
  let reader = await new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onload = (e) => {
    setFile({
      data: reader.result.split(',').pop(),
      fileName: event.target.files[0].name,
    });
  };
};

export const getObjectSubset = (obj, ...keys) =>
  Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  );

export const findObjectInArray = (arr, keyName, matchingKey) =>
  arr.find((o) => o[keyName] === matchingKey);

export const getInitialDataFormik = (arr, key, valueKey) => {
  let data = {};
  if (arr.length) {
    arr.forEach((element) => {
      data[element[key]] = element[valueKey] || '';
    });
  }
  return data;
};

export const getArrayOfObjJoinToStringForKey = (arr, key) => {
  return arr?.map((u) => u[key])?.join(', ') || '';
};
export const showToastSuccess = (msg) => {
  return toast.success(msg);
};

export const showToastWarning = (msg) => {
  return toast.warning(msg);
};

export const showToastError = (msg) => {
  return toast.error(msg);
};
export function humanize(str) {
  try {
    var i,
      frags = str.toLowerCase()?.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i]?.charAt(0)?.toUpperCase() + frags[i]?.slice(1);
    }
    return frags?.join(' ');
  } catch (e) {
    console.log(e, 'humanize');
  }
}

export function isNumeric(value) {
  return /^\d+$/.test(value);
}

export function removeExtraSpace(string, type = '') {
  let str = string;
  if (type === 'all') {
    str = str.replace(/\s/g, '');
  } else if (type === ' , ') {
    str = str.replace(/\s*,\s*/g, ',');
  } else {
    str = str.replace(/\s+/g, ' ').trim();
  }
  return str;
}

// handleParse  and processCSV is used to read and process csv files   https://dev.to/theallegrarr/read-csv-files-in-react-apps-without-installing-any-package-hn7

export const handleParse = (file) => {
  return new Promise((resolve, reject) => {
    try {
      // Initialize a reader which allows user
      // to read any file or blob.
      const reader = new FileReader();
      // Event listener on reader when the file
      // loads, we parse it and set the data.
      reader.onload = function (e) {
        const text = e.target.result;
        resolve(text);
      };
      reader.readAsText(file);
    } catch (e) {}
  });
};

export const processCSV = (str, delim = ',', dataNeeded = 'headers') => {
  const headers = str
    .slice(0, str.indexOf('\n'))
    .split(delim)
    .map((item) => {
      let itemData = item.replace('\r', '');
      return itemData;
    });
  if (dataNeeded === 'headers') {
    return headers;
  } else if (dataNeeded === 'csvAsArray') {
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const csvArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    return csvArray;
  }
  return [];
};

export function validDate(date, compareDate = null, checkEqual = false) {
  let current_date = new Date();
  if (compareDate) {
    current_date = new Date(compareDate);
  }
  let incoming_date = new Date(date);
  if (current_date.getTime() < incoming_date.getTime()) {
    return true;
  } else if (current_date.getTime() <= incoming_date.getTime() && checkEqual) {
    return true;
  } else {
    return false;
  }
}

export const crypt = (salt = 'crypt', textStr) => {
  let text = String(textStr?.toString());
  const textToChars = (text) => text?.split('').map((c) => c?.charCodeAt(0));
  const byteHex = (n) => ('0' + Number(n)?.toString(16))?.substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt)?.reduce((a, b) => a ^ b, code);
  return (
    text
      ?.split('')
      ?.map(textToChars)
      ?.map(applySaltToChar)
      ?.map(byteHex)
      ?.join('') || ''
  );
};

export const decrypt = (salt = 'crypt', encoded) => {
  const textToChars = (text) => text?.split('')?.map((c) => c?.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt)?.reduce((a, b) => a ^ b, code);
  return (
    encoded
      ?.toString()
      ?.match(/.{1,2}/g)
      ?.map((hex) => parseInt(hex, 16))
      ?.map(applySaltToChar)
      ?.map((charCode) => String?.fromCharCode(charCode))
      ?.join('') || ''
  );
};

export function generateArrayOfYears(yearsNeed = 9) {
  var max = new Date().getFullYear();
  var min = max - yearsNeed;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function generateArrayOfMonths() {
  const month = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];
  return month;
}

export const createSubString = (
  str = '',
  onClick = () => {},
  subStrLength = 99,
  clickText
) => {
  let newStr = str;
  try {
    if (str.length > subStrLength) {
      newStr = (
        <>
          {str.substring(0, subStrLength)}...{' '}
          {!!clickText && (
            // eslint-disable-next-line
            <a
              onClick={() => {
                onClick();
              }}
            >
              {clickText}
            </a>
          )}
        </>
      );
    } else {
      newStr = str;
    }
    return newStr;
  } catch (e) {
    return str;
  }
};

export const checkPaymentOnline = (data) => {
  let isOnline = false;
  if (data?.payment_type) {
    if (
      !(
        data?.payment_type?.toLowerCase()?.includes('bank transfer') ||
        data?.payment_type?.toLowerCase()?.includes('cheque') ||
        // || data?.payment_type?.toLowerCase()?.includes('card')
        data?.payment_type?.toLowerCase()?.includes('demand draft') ||
        data?.payment_type?.toLowerCase()?.includes('draft') ||
        data?.payment_type?.toLowerCase()?.includes('demand') ||
        data?.payment_type?.toLowerCase()?.includes('neft') ||
        data?.payment_type?.toLowerCase()?.includes('rtgs') ||
        data?.payment_type?.toLowerCase()?.includes('wt')
      )
    ) {
      isOnline = true;
    } else {
      isOnline = false;
    }
  }

  return isOnline;
};

export async function fetchFile(url, header = {}, target = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const aTag = document.createElement('a');
      aTag.href = url;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      // aTag.download = true;
      // aTag.download = url.replace(/^.*[\\\/]/, "");
      document.body.appendChild(aTag);
      aTag.click();
      // URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      toast.error(toastMsg.downloadFail);
      reject();
    }
  });
}

export async function fetchInPromiseFile(url, header = {}, target = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(url);
      const file = await res.blob();
      let tempUrl = URL.createObjectURL(file);
      const aTag = document.createElement('a');
      aTag.href = tempUrl;
      if (target) {
        aTag.target = target || '_blank';
      }
      aTag.id = 'downloadId';
      aTag.download = true;
      // eslint-disable-next-line
      aTag.download = url.replace(/^.*[\\\/]/, '');
      document.body.appendChild(aTag);
      aTag.click();
      URL.revokeObjectURL(tempUrl);
      aTag.remove();
      resolve();
    } catch {
      toast.error(toastMsg.downloadFail);
      reject();
    }
  });
}

export function downloadBlobFile(data, name = 'File') {
  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.download = name; //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function downloadBlobXLSFile(data, name = 'File') {
  // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.

  try {
    const outputFilename = `${name}.xlsx`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    throw Error(error);
  }
}

export function getParameters() {
  let parameters = window.location.search.replace('?', '');

  let currentParameters = {};

  if (Object.keys(parameters).length) {
    parameters = parameters.split('&');

    for (let i = 0; i < parameters.length; i++) {
      let parameter = parameters[i].split('=');
      if (parameter[0] !== 'tab') {
        currentParameters[parameter[0]] = decrypt(
          parameter[0],
          parameter[1].replaceAll('%20', ' ') || ''
        );
      } else {
        currentParameters[parameter[0]] = parameter[1].replaceAll('%20', ' ');
      }
    }
  }

  return currentParameters;
}

export function getNewParameters(newParameters) {
  const parameters = getParameters();
  const parametersKeys = Object.keys(parameters);
  const keys = Object.keys(newParameters);

  // console.log(parametersKeys, keys, parameters)

  for (let i = 0; i < parametersKeys.length; i++) {
    if (!keys.includes(parametersKeys[i]) && parametersKeys[i] !== 'tab') {
      delete parameters[parametersKeys[i]];
    }
  }
  for (let i = 0; i < keys.length; i++) {
    const value = newParameters[keys[i]];
    parameters[keys[i]] = value;

    if (!value) {
      delete parameters[keys[i]];
    }
  }
  let newUrl = updateUrl(parameters);

  return { parameters, ...newUrl };
}

export function updateUrl(parameters) {
  let search = '';
  let j = 0;
  let separator = '?';

  Object.keys(parameters).forEach((key) => {
    let value = parameters[key];

    if (value) {
      if (j !== 0) {
        separator = '&';
      }
      if (key !== 'tab') {
        search += `${separator}${key}=${crypt(key, value || '')}`;
      } else {
        search += `${separator}${key}=${value}`;
      }
      j++;
    }
  });

  let newUrl = `${window.location.pathname}${search}`;

  // prevents pushing same url if function won't change url.
  if (window.location.href !== newUrl) {
    return { newUrl, search };
    // history.pushState(null, null, newUrl);
  }
}

export function isAccessiblePath(path) {
  let data = getObjectFromStore('userMenu');
  let userData = getObjectFromStore('user');
  if (data?.length) {
    let item = data?.findIndex((pathName) => pathName?.value === path);
    if (userData?.is_superadmin === '1') return true;
    else if (path === 'admin_users' && userData?.is_superadmin === '0')
      return false;
    return item >= 0 ? true : false;
  }
  return false;
}

export function isJson(str) {
  let parsed = str;
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    return { status: false, data: null };
  }
  return { status: true, data: parsed };
}

export const createCommonSettingCategoryTableData = (data, parentKey) => {
  let parsedData = isJson(data);
  if (parsedData?.status) {
    let newData = parsedData.data;
    let keys = Object.keys(newData);
    let updatedData = keys.map((item, index) => {
      return {
        parent_key: parentKey,
        key_name: item,
        key_value: newData[item],
      };
    });
    return updatedData;
  }
  return [];
};
export const normalFormateDateTime = (date) => {
  const dateResult = moment(date);
  const result = dateResult.format('MM/DD/YYYY hh:mm a');
  return result;
};
export const getFileName = (image) => {
  return `${image.name.replace(/\s+/g, '')}`.split('.')[0] + `__${uuidv4()}`;
};
export const copyTextToClipBoard = (copyValue) => {
  navigator.clipboard.writeText(copyValue);
};

//Sort array of objects

export const sortArrayObject = (arrObj = [], baseKey = '') => {
  let arrayObjToSort = [...arrObj];
  let sortedObject = arrayObjToSort.sort((obj1, obj2) => {
    let a = obj1[baseKey],
      b = obj2[baseKey];
    return a - b;
  });
  return sortedObject;
};
