import { Typography } from '@mui/material';
import MainLayout from '../Layouts/MainLayout';
import IdeaShareContainer from './IdeaShareContainer';

const IdeaSharePage = () => {
  return (
    <MainLayout>
      <IdeaShareContainer />
    </MainLayout>
  );
};

export default IdeaSharePage;
