import { Tabs, Tab } from '@mui/material';
import CustomCircularProgress from '../../Custom Components/CustomCircularProgress/CustomCircularProgress';

const TabWithScore = ({ selectedTab = 0, handleTabChange, tabData = [] }) => {
  return (
    <Tabs
      TabIndicatorProps={{ className: 'customIndicator1' }}
      value={selectedTab}
      onChange={handleTabChange}
    >
      {tabData.length &&
        tabData?.map((tabOption, index) => (
          <Tab
            key={'customTab' + index}
            className="dashboardTab1 textAlignLeft maxWidth220"
            disableRipple={true}
            label={tabOption?.tabName}
            iconPosition="start"
            icon={
              <CustomCircularProgress
                circleSize={20}
                showStat={false}
                value={tabOption?.completionPercentage}
              />
            }
          />
        ))}
    </Tabs>
  );
};
export default TabWithScore;
