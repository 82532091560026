import {
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';
import '../../Styles/commonStyles.css';
import { labels, errors } from '../../data/constants';
import { useEffect, useState } from 'react';
const AddIdeaModal = ({
  showAddIdeaModal = false,
  close = false,
  handleCancel = () => {},
  handleIdeaSubmit = () => {},
}) => {
  const [ideaName, setIdeaName] = useState('');
  const [ideaSummary, setIdeaSummary] = useState('');
  const [ideaNameError, setIdeaNameError] = useState({
    status: true,
    message: ' ',
  });
  const [ideaSummaryError, setIdeaSummaryError] = useState({
    status: true,
    message: ' ',
  });
  
  const handleSubmit = () => {
    if (ideaName === '') {
      setIdeaNameError({
        status: true,
        message: errors.businessIdeas.addIdeaModal.ideaName,
      });
      return;
    }
    if (ideaSummary === '') {
      setIdeaSummaryError({
        status: true,
        message: errors.businessIdeas.addIdeaModal.ideaSummary,
      });
      return;
    }
    handleIdeaSubmit(ideaName, ideaSummary);
  };
  return (
    <CustomDialog
      handleOpen={showAddIdeaModal}
      showClose={close}
      dialogTitle={labels.businessIdeas.addIdeaModal.title}
      width={'xs'}
      headingVariant="dashboardCompHeading4"
    >
      <Box component={'form'}>
        <InputLabel required className="dashboardLabel1 fontWeight500 mb05">
          {labels.businessIdeas.addIdeaModal.nameLabel}
        </InputLabel>
        <TextField
          fullWidth
          className="textFieldType3"
          placeholder={labels.businessIdeas.addIdeaModal.namePlaceholder}
          value={ideaName}
          onChange={(e) => {
            setIdeaNameError({ status: false, message: ' ' });
            setIdeaName(e.target.value);
          }}
        />
        <FormHelperText error={ideaNameError.status}>
          {ideaNameError.message}
        </FormHelperText>
        <InputLabel
          required
          className="dashboardLabel1 fontWeight500 mt05 mb05"
        >
          {labels.businessIdeas.addIdeaModal.summaryLabel}
        </InputLabel>
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={2}
          className="textFieldType3"
          placeholder={labels.businessIdeas.addIdeaModal.summaryPlaceholder}
          value={ideaSummary}
          onChange={(e) => {
            setIdeaSummaryError({ status: false, message: ' ' });
            setIdeaSummary(e.target.value);
          }}
        />
        <FormHelperText error={ideaSummaryError.status}>
          {ideaSummaryError.message}
        </FormHelperText>
        <Typography variant="infoText1" className="my1" component={'p'}>
          {labels.businessIdeas.addIdeaModal.infoText}
        </Typography>
        <Box className="justifySpaceBetween displayFlex mt1">
          <Button variant="buttonVariant1" onClick={handleCancel}>
            {labels.commonLabels.cancelLabel}
          </Button>
          <Button variant="buttonVariant1" onClick={handleSubmit}>
            {labels.commonLabels.submitLabel}
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
};
export default AddIdeaModal;
