import { Box, Typography, Checkbox, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { labels } from '../../../data/constants';
import SetupStatusSkeleton from './SetupStatusSkeleton';
import { sortArrayObject } from '../../../lib/helper';
const SetupStatus = ({ dashboardItems, hasRole = null }) => {
  const [stepsData, setStepsData] = useState(false);
  useEffect(() => {
    if (dashboardItems?.length) {
      let sortedDashboardItems = sortArrayObject(
        dashboardItems,
        'dashboardMenuId'
      );
      //keywords to search in the points string list.
      let searchItems = [
        'profile',
        'Business ideas',
        'Research',
        'Filter',
        'Business Plan',
      ];
      let newPoints = [];
      //iterating through each success point.
      for (let j = 0; j < sortedDashboardItems.length; j++) {
        //iterating through each search keyword for each 'success point'
        for (let i = 0; i < searchItems.length; i++) {
          //If we found a match of a serach keyword in a 'success point' we split it and create a new object with 'status' and a 'typography' element with a link inside it.
          if (
            sortedDashboardItems[j]?.dashboardMenu?.name.search(
              searchItems[i]
            ) !== -1
          ) {
            //split it
            let regex = new RegExp(`(${searchItems[i]})`, 'gm');
            let newItem =
              sortedDashboardItems[j]?.dashboardMenu?.name.split(regex);
            newItem.forEach((item, index) => {
              //Searched keyword will be removed by split function and an array of two ites will be provided. Now finding the array item with empty data.
              if (item === searchItems[i]) {
                //Adding the 'typography' component with link with the keyword which was needed to be a link.
                newItem[index] = (
                  <Typography
                    variant="successPointLink"
                    component={Link}
                    key={'typography' + index}
                    to={
                      '/' +
                      searchItems[i].replace(/\s/g, '').toLocaleLowerCase()
                    }
                  >
                    {searchItems[i]}
                  </Typography>
                );
              }
            });
            //assigning it to a new array.
            newPoints[j] = {
              status: sortedDashboardItems[j]?.status,
              name: <Typography variant="successPoint">{newItem}</Typography>,
            };
            break;
          } else {
            //If no keyword matches then we simply pass it with zero link.
            newPoints[j] = {
              status: sortedDashboardItems[j].status,
              name: (
                <Typography variant="successPoint">
                  {sortedDashboardItems[j].dashboardMenu.name}
                </Typography>
              ),
            };
          }
        }
      }
      setStepsData([...newPoints]);
    } else {
      setStepsData([]);
    }
  }, [dashboardItems]);
  return (
    <Box
      mt={2}
      ml={2}
      mr={2}
    >
      <Box className="displayFlex justifyCenter">
        <Box>
          <Typography
            component={'h3'}
            variant={'dashboardCompHeading3'}
            className="textAlignLeft ml10p"
          >
            {labels.setupStatus.heading}
          </Typography>
          <Grid>
            {stepsData ? (
              stepsData?.map((item, index) => {
                return (
                  <Box
                    className="displayFlex alignItemsCenter"
                    key={'successPoint' + index}
                  >
                    <Checkbox
                      disableRipple
                      sx={{ cursor: 'default' }}
                      checked={item?.status === 'pending' ? false : true}
                    />
                    {item?.name}
                  </Box>
                );
              })
            ) : (
              <SetupStatusSkeleton />
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default SetupStatus;
