import DashboardLayout from "../Layouts/DashboardLayout";
import WorkspaceComp from "./WorkspaceComp";

const WorkspaceContainer = () => {
  return (
    <DashboardLayout>
      <WorkspaceComp />
    </DashboardLayout>
  );
};
export default WorkspaceContainer;
