import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';
const CustomCircularProgress = ({
  showStat = true,
  circleSize = 40,
  ...props
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', marginRight: 1.5 }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: 'rgb(243, 243, 243)',
          position: 'absolute',
          left: 0,
        }}
        thickness={4}
        value={100}
        size={circleSize}
      />
      <CircularProgress
        size={circleSize}
        sx={{
          color: () =>
            props.value <= 25
              ? 'rgb(204, 84, 189)'
              : props.value > 25 && props.value < 100
              ? 'rgb(242, 178, 15)'
              : props.value === 100
              ? 'rgb(84, 171, 114)'
              : '#1976d2',
        }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {showStat && (
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

CustomCircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CustomCircularProgress;
