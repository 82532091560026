import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { toast } from 'react-toastify';
import { errorMessage } from '../lib/helper';
export const getUserTabsService = async (payload) => {
  try {
    const response = await axios.post(`${ApiConfig.userTabs}`,payload);
    if (
      response?.data?.status >= STATUS_200 &&
      response?.data?.status < STATUS_300
    ) {
      return response?.data?.data;
    } else {
      throw response;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const submitProfileDataService = async (payload) => {
  try {
    const response = await axios.post(`${ApiConfig.questionAnswers}`, payload);
    if (
      response?.data?.status >= STATUS_200 &&
      response?.data?.status < STATUS_300
    ) {
      toast.success(response?.data?.message, {
        position: 'bottom-right',
        autoClose: 2500,
        hideProgressBar: true,
      });
      return true;
    } else {
      toast.error(response?.data?.message);
      throw response;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const fetchUserResumeService = async (payload) => {
  try {
    const response = await axios.post(`${ApiConfig.fetchResume}`, payload, {
      responseType: 'blob',
    });
    if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
      return response?.data;
    } else {
      toast.error(response?.message);
      throw response;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
