import Axios from 'axios';
import ApiConfig from './config/ApiConfig';
import {
  clearStorage,
  getObjectFromStore,
  storageKeys,
} from './storage/Storage';
import { toast } from 'react-toastify';

Axios.interceptors.request.use((request) => requestHandler(request));

Axios.interceptors.response.use(
  (response) => {
    checkTokenIsValid(response);
    return response;
  },
  (error) => errorHandler(error)
);
const checkTokenIsValid = (response) => {
  if (response?.data?.status === 401 || response?.data?.status === 403) {
    clearStorage();
    toast.error(response.data.message, {
      autoClose: 2500,
    });
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }
};

const requestHandler = async (request) => {
  let token = ApiConfig.token;
  if (!token) {
    token = await getObjectFromStore(storageKeys.token);
  }
  request.headers = {
    Authorization: `Bearer ${token}`,
    // 'X-CSCAPI-KEY': "RmtmRFFKZDg3Y0cyaTVMWlNpNHVYcjV2VWZ3OWZGWU9nRHlpYkJuNQ==",
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return request;
};

const errorHandler = async (error) => {
  checkTokenIsValid(error.response);
  throw error.response;
};
