import DashboardLayout from '../Layouts/DashboardLayout';
import IdeaDetailsComp from './IdeaDetailsComp';

const IdeaDetailsContainer = () => {
  return (
    <DashboardLayout>
      <IdeaDetailsComp />
    </DashboardLayout>
  );
};
export default IdeaDetailsContainer;
