import React from 'react';
import './App.css';
import { AuthProvider } from './Context/AuthProvider';
import { Provider } from 'react-redux';
import AuthRoute from './Route/AuthRoute';
import { store } from './redux/store';
import './AxiosInterceptor';
import { ThemeProvider } from '@mui/material';
import { customTheme } from './Theme/custom-theme';

function App() {
  return (
    <>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider theme={customTheme}>
            <AuthRoute />
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </>
  );
}

export default App;
