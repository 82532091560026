import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Tooltip,
  TextField,
} from '@mui/material';
import MultiSelectChip from '../Custom Components/MultiSelectChip/MultiSelectChip';
import CustomRadioGroup from '../Custom Components/RadioGroup/RadioGroup';
import { Info } from '@mui/icons-material';
import { useState } from 'react';
const ProfileTabBlock = ({  
  blockDetail,
  handleAnswerSubmit = () => {},
  dataHandler = () => {},
}) => {
  const handleRadioChange = (e) => {
    let answer = e?.target?.value;
  };

  const handleChipDeleteFunc = (questionId, choiceToDelete) => {
    let questionData = {
      id: '',
      answer: '',
    };
    blockDetail.forEach((questionOption) => {
      if (questionOption.id === questionId) {
        questionData.id = questionId;
        questionData.answer = questionOption.answer;
      }
    });
    questionData.answer.pop(choiceToDelete);
    dataHandler(questionData.id, questionData.answer);
  };
  return (
    <Box mt={2} ml={2}>
      <Grid container columnSpacing={2}>
        {blockDetail?.map((blockOption, index) => {
          if (blockOption.status === true) {
            return (
              <Grid key={'blockOption' + index} item mb={2} lg={6}>
                <InputLabel
                  sx={{ overflow: 'visible' }}
                  htmlFor={blockOption?.title}
                  shrink={false}
                  className="dashboardLabel1"
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {blockOption?.question}
                    {blockOption?.toolTip && (
                      <Tooltip title={blockOption?.toolTip}>
                        <Info fontSize="small" sx={{ marginLeft: 1 }} />
                      </Tooltip>
                    )}
                  </Typography>
                </InputLabel>
                {blockOption?.type === 'textField' ? (
                  <TextField
                    sx={{ marginTop: 2 }}
                    id={blockOption?.title}
                    placeholder={blockOption?.placeholder}
                    className="textFieldType2"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={blockOption?.answer || ''}
                    onChange={(e) => {
                      dataHandler(blockOption?.id, e.target.value);
                    }}
                    onBlur={() => {
                      handleAnswerSubmit(blockOption?.id, blockOption?.answer);
                    }}
                  />
                ) : blockOption?.type === 'multiSelect' ? (
                  <MultiSelectChip
                    labelId={blockOption?.title}
                    selectId={blockOption?.title}
                    selectedValue={blockOption?.answer || []}
                    dropDownItems={blockOption?.options}
                    handleChange={(value) => {
                      dataHandler(blockOption?.id, value);
                    }}
                    handleBlur={() => {
                      handleAnswerSubmit(blockOption?.id, blockOption?.answer);
                    }}
                  />
                ) : blockOption?.type === 'radio' ? (
                  <CustomRadioGroup
                    ariaLabel={blockOption?.title}
                    radioGroupName={blockOption?.title}
                    radioOptions={blockOption?.options}
                    // selectedValue={recurringRevenueType}
                    handleChange={handleRadioChange}
                  />
                ) : (
                  ''
                )}
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
};

export default ProfileTabBlock;
