import { Grid, Skeleton } from "@mui/material";
const ProfileTabSkeleton = () =>{
    return(<Grid container columnSpacing={1}>
        <Grid
          item
          xs={4}
          md={3}
          lg={2}
          className="displayFlex alignItemsCenter"
        >
          <Skeleton
            variant="circular"
            width={30}
            height={30}
            className="mr05"
          />
          <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid
          item
          xs={4}
          md={3}
          lg={2}
          className="displayFlex alignItemsCenter"
        >
          <Skeleton
            variant="circular"
            width={30}
            height={30}
            className="mr05"
          />
          <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid
          item
          xs={4}
          md={3}
          lg={2}
          className="displayFlex alignItemsCenter"
        >
          <Skeleton
            variant="circular"
            width={30}
            height={30}
            className="mr05"
          />
          <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
        </Grid>
      </Grid>);
}

export default ProfileTabSkeleton;