import { Box, Grid, Skeleton } from '@mui/material';

const QuickIdeasSkeleton = () => {
  return (
    <Box className="mt1">
      <Grid className="mt05" container columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'100%'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuickIdeasSkeleton;
