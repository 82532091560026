import { Box, Grid, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

const IdeaShareSkeleton = ({ skeletonCount = 0 }) => {
  const [skeletonData, setSkeletonData] = useState([]);
  useEffect(() => {
    let skeletonArray = [];
    let skeleton = (
      <Grid container className="mt1">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={10} alignContent={'center'}>
              <Skeleton
                width={'20%'}
                sx={{ fontSize: '1rem' }}
                variant="text"
              />
            </Grid>
          </Grid>
          <Box className="mt1">
            <Skeleton sx={{ fontSize: '1rem' }} variant="text" />
            <Skeleton width={'50%'} sx={{ fontSize: '1rem' }} variant="text" />
          </Box>
        </Grid>
      </Grid>
    );
    for (let i = 0; i < skeletonCount; i++) {
      skeletonArray.push(skeleton);
    }
    setSkeletonData([...skeletonArray]);
  }, []);
  return <>{skeletonData}</>;
};

export default IdeaShareSkeleton;
