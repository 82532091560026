import DashboardLayout from '../Layouts/DashboardLayout';
import BusinessPlanComp from './BusinessPlanComp';
const BusinessPlanContainer = () => {
  return (
    <DashboardLayout>
      <BusinessPlanComp />
    </DashboardLayout>
  );
};
export default BusinessPlanContainer;
