import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function MaintenanceLayout() {
	const navigate = useNavigate()

	useEffect(() => {
		if (process.env.REACT_APP_ENV === 'prod') {
			if ('1' != "1") {
				navigate('/dashboard', { replace: true })
							}
		}
	}, [navigate]);

	return (
		<div >
			Website Under Construction
		</div>
	)
}

export default MaintenanceLayout