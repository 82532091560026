import { Typography } from "@mui/material";
import { labels } from "../../data/constants";
import { Link } from "react-router-dom";

function Welcome() {
  return (
    <>
      <Typography
        component={"h3"}
        variant={""}
        mb={3}
        className="textAlignCenter"
      >
        {labels.welcome.heading}
      </Typography>
      <Typography
        component={"p"}
        variant={""}
        mb={3}
        className="textAlignCenter"
      >
        {labels.welcome.subHeading1}
        <Typography
          variant="successPointLink"
          component={Link}
          to={"/profile"}
        >
          profile
        </Typography>
        {labels.welcome.subHeading2}
        <Typography
          variant="successPointLink"
          component={Link}
          to={"/businessideas"}
        >
          Business Ideas
        </Typography>
        {labels.welcome.subHeading3}
      </Typography>
    </>
  );
}
export default Welcome;
