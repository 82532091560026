import MainLayout from '../Layouts/MainLayout';
import SignupForm from './SignupForm';

const Signup = () => {
  return (
    <MainLayout>
      <SignupForm />
    </MainLayout>
  );
};

export default Signup;
