import {
  OutlinedInput,
  Chip,
  MenuItem,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import { profileLabels } from '../../../data/constants';
import Select from 'react-select';
const MultiSelectChip = ({
  selectId,
  handleBlur = () => {},
  selectedValue = [],
  dropDownItems,
  handleChipDelete,
  handleChange,
}) => {
  return (
    // <Select
    //   displayEmpty
    //   id={selectId}
    //   multiple
    //   value={selectedValue}
    //   className="dashboardSelect1"
    //   fullWidth
    //   onChange={handleChange}
    //   onBlur={handleBlur}
    //   input={<OutlinedInput id="select-multiple-chip" />}
    //   renderValue={(selected) => {
    //     if (selected.length !== 0) {
    //       return (
    //         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    //           {selected.map((value) => (
    //             <Chip
    //               size="small"
    //               key={value}
    //               label={value}
    //               onMouseDown={(e) => {
    //                 e.stopPropagation();
    //               }}
    //               onDelete={() => {
    //                 handleChipDelete(value);
    //               }}
    //             />
    //           ))}
    //         </Box>
    //       );
    //     } else {
    //       return <Typography>{profileLabels.selectOption}</Typography>;
    //     }
    //   }}
    // >
    //   {dropDownItems &&
    //     dropDownItems.map((option) => (
    //       <MenuItem key={option.value} value={option.value}>
    //         {option.label}
    //       </MenuItem>
    //     ))}
    // </Select>
    // <Autocomplete
    //   multiple
    //   id="multiSelectChip"
    //   size='small'
    //   options={dropDownItems}
    //   className="dashboardSelect1"
    //   getOptionLabel={(option) => option.label}
    //   value={selectedValue}
    //   onChange={handleChange}
    //   filterSelectedOptions
    //   renderInput={(params) => (
    //     <TextField {...params} placeholder={profileLabels.selectOption} />
    //   )}
    // />
    <Select
      placeholder={profileLabels.selectOption}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          marginTop: '15px',
          minHeight: '40px',
        }),
      }}
      isMulti
      value={selectedValue}
      options={dropDownItems}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default MultiSelectChip;
