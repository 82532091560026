import DashboardLayout from '../Layouts/DashboardLayout';
import ProfileComp from './ProfileComp';

const ProfileContainer = () => {
  return (
    <DashboardLayout>
      <ProfileComp />
    </DashboardLayout>
  );
};
export default ProfileContainer;
