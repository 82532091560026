import DashboardLayout from '../Layouts/DashboardLayout';
import ProComp from './ProComp';

const ProContainer = () => {
  return (
    <DashboardLayout>
      <ProComp />
    </DashboardLayout>
  );
};
export default ProContainer;
