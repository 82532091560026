import CustomDialog from '../../Components/Custom Components/CustomDialog/CustomDialog';
import { Typography, Button, Box } from '@mui/material';
import { labels } from '../../data/constants';
const SuccessModal = ({ handleOkay = () => {} }) => {
  return (
    <CustomDialog showClose={false}>
      <Typography className="textCenter fontBold fontSize18">
        {labels.accounts.successModal.emailChangeSuccess}
      </Typography>
      <Typography className="mt2 textCenter">
        {labels.accounts.successModal.userMsg}
      </Typography>
      <Box className="displayFlex mt2 justifyCenter alignContentCenter alignItemsCenter">
        <Button variant="buttonVariant1" onClick={handleOkay}>
          {labels.accounts.successModal.btnLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default SuccessModal;
