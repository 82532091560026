import DashboardLayout from "../Layouts/DashboardLayout";
import InvitationComp from "./InvitationComp";

const InvitationContainer = () => {
  return (
    <DashboardLayout>
      <InvitationComp />
    </DashboardLayout>
  );
};
export default InvitationContainer;