import DashboardLayout from '../Layouts/DashboardLayout';
import FilterComp from './FilterComp';
const FilterContainer = () => {
  return (
    <DashboardLayout>
      <FilterComp />
    </DashboardLayout>
  );
};
export default FilterContainer;
