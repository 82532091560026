import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { labels } from "../../data/constants";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useEffect, useState } from "react";
import {
  generateCareerPathways,
  getCareerPathways,
} from "../../Services/CareerPathwayService";
import { useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const CareerPathwayComp = () => {
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const isGuest = userWorkspace?.role === "guest" ? true : false;
  const [loading, setLoading] = useState(false);
  const [pathwayData, setPathwayData] = useState("");
  const [noData, setNoData] = useState("");
  useEffect(() => {
    if (userWorkspace) {
      getPathways();
    }
  }, [userWorkspace]);
  const getPathways = async () => {
    const payload = {
      workspaceid: userWorkspace?.id,
    };
    const response = await getCareerPathways(payload);
    if (!response?.data?.careerPath) {
      setPathwayData("");
      setNoData(response?.message);
    } else {
      setNoData("");
      setPathwayData(response?.data?.careerPath);
    }
  };
  const generatePathways = async () => {
    setLoading(true);
    const payload = {
      workspaceid: userWorkspace?.id,
    };
    await generateCareerPathways(payload);
    await getPathways();
    setLoading(false);
  };
  return (
    <>
      <Box mt={2} mr={2} pb={3}>
        <Grid container>
          <Grid item lg={10} display={"flex"} alignItems={"center"}>
            <Typography ml={2} component={"h1"} variant="dashboardCompHeading">
              {labels.careerPathways.title}
            </Typography>
            {!loading ? (
              <IconButton
                disabled={isGuest}
                onClick={generatePathways}
                aria-label={
                  pathwayData
                    ? labels.careerPathways.regeneratePathways
                    : labels.careerPathways.generatePathways
                }
                className="iconButtonType2 ml1"
              >
                <AutorenewIcon className="mr05" />
                {pathwayData
                  ? labels.careerPathways.regeneratePathways
                  : labels.careerPathways.generatePathways}
              </IconButton>
            ) : (
              <Box className="displayFlex alignItemsCenter ml05">
                <CircularProgress size={"1rem"} />
                <Typography className="ml05" variant="paraText1">
                  {pathwayData
                    ? labels.careerPathways.regeneratePathways
                    : labels.careerPathways.generatePathways}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider className="mt1"></Divider>
        {noData && !isGuest && (
          <Typography
            component={"h2"}
            className="displayFlex alignItemsCenter justifyCenter mt5 fontWeightBold fontSize24"
            gap={1}
          >
            <ErrorOutlineIcon /> {noData}{" "}
            {labels.careerPathways.noDataFoundLabel}
          </Typography>
        )}
        {noData && isGuest && (
          <Typography
            component={"h2"}
            className="displayFlex alignItemsCenter justifyCenter mt5 fontWeightBold fontSize24"
            gap={1}
          >
            <ErrorOutlineIcon /> {labels.careerPathways.guestAccessDeniedLabel}
          </Typography>
        )}
        {pathwayData && (
          <Typography
            className="pb3 mt2 ml2 mr2"
            dangerouslySetInnerHTML={{ __html: pathwayData }}
          ></Typography>
        )}
      </Box>
    </>
  );
};

export default CareerPathwayComp;
