import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { clearStorage } from '../storage/Storage';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../redux/login';
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useLocalStorage('user', null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    navigate('/');
   
  };
  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    dispatch(setUserDetails(false));
    clearStorage();
    navigate('/', { replace: true });
  };
  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser: setUser,
    }),
    // eslint-disable-next-line
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
