import Select from 'react-select';
import { profileLabels } from '../../../data/constants';
const CustomSelect = ({
  handleBlur = () => {},
  selectedValue = [],
  dropDownItems,
  handleChange,
  multi,
  disabled,
  customStyles = {
    marginTop: '15px',
    minHeight: '40px',
  },
}) => {
  return (
    <Select
      placeholder={profileLabels.selectOption}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          ...customStyles,
        }),
      }}
      isMulti={multi}
      value={selectedValue}
      options={dropDownItems}
      onChange={handleChange}
      onBlur={handleBlur}
      isDisabled={disabled}
    />
  );
};

export default CustomSelect;
