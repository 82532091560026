import { Grid } from '@mui/material';
import Header from '../../Components/MainLayout/Header/header';
import { useSelector } from 'react-redux';

function MainLayout(props) {
  const userDetails = useSelector(
    (state) => state?.loginUserData?.user?.userDetails
  );
  return (
    <Grid container>
      <Grid className='paddingTop80' item xs={12} sm={12}>
        <Header isLoggedIn={userDetails} />
        {props.children}
      </Grid>
    </Grid>
  );
}

export default MainLayout;
