import DashboardLayout from '../Layouts/DashboardLayout';
import Dashboard from './Dasboard';

const DashboardContainer = () => {
  return (
    <DashboardLayout>
      <Dashboard />
    </DashboardLayout>
  );
};

export default DashboardContainer;
