import ApiConfig from "../config/ApiConfig";
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from "../data/constants";
import axios from "axios";
import { errorMessage } from "../lib/helper";
import { toast } from "react-toastify";

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const getCareerPathways = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.getCareerPathways}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        console.log("getCareerPathways 1");
      return resp?.data;
      
    } else {
        console.log("getCareerPathways 2");

      throw resp;
    }
  } catch (e) {
    console.log("getCareerPathways 3",e);
    if(e.status === 404){
        return e;
    }else{
        showErrorMessage(e);
        return false;
    }
    
  }
};

export const generateCareerPathways = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.generateCareerPathways}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
