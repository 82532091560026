import { Tabs, Tab } from '@mui/material';
import CustomCircularProgress from '../CustomCircularProgress/CustomCircularProgress';
import ProfileTabSkeleton from '../../Profile/ProfileTabSkeleton';
const CustomTabs = ({ selectedTab, handleTabChange, tabData = [] }) => {
  return (
    <Tabs
      TabIndicatorProps={{ className: 'customIndicator1' }}
      value={selectedTab}
      onChange={handleTabChange}
    >
      {tabData.length ? (
        tabData.map((tabOption, index) => (
          <Tab
            key={'customTab' + index}
            className="dashboardTab1 textAlignLeft maxWidth220"
            disableRipple={true}
            label={tabOption?.tabName}
            iconPosition="start"
            icon={
              <CustomCircularProgress
                circleSize={20}
                showStat={false}
                value={tabOption?.completionPercentage}
              />
            }
          />
        ))
      ) : (
        <ProfileTabSkeleton />
      )}
    </Tabs>
  );
};

export default CustomTabs;
