import { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { labels } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import IosShareIcon from '@mui/icons-material/IosShare';
import IdeaShareControlModal from '../CommonComponents/IdeaShareModal/IdeaShareControlModal';
import IdeaShareMenu from '../CommonComponents/IdeaShareMenu/IdeaShareMenu';
const IdeaDetailHeader = ({ ideaDetail, updateShare = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showShareEditModal, setShowShareEditModal] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShareUpdate = async (ideaId, shareStatus, shareLevel) => {
    const response = await updateShare(ideaId, shareStatus, shareLevel);
    if (response) {
      setShowShareEditModal(false);
    } else {
      setShowShareEditModal(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          navigate('/businessIdeas');
        }}
        variant="textButton2"
      >
        {labels.ideaDetails.returnButtonLabel}
      </Button>
      <Grid container className="mt1">
        <Grid item xs={12} sm={11}>
          <Typography component={'h1'} variant="dashboardCompHeading">
            {labels.ideaDetails.pageTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <IconButton onClick={handleClick} className="iconButtonType3">
            <IosShareIcon />
            {labels.ideaDetails.shareButtonLabel}
          </IconButton>
          <IdeaShareMenu
            ideaDetail={ideaDetail}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleShareOpen={() => {
              setShowShareEditModal(true);
            }}
            open={open}
          />
        </Grid>
      </Grid>
      {showShareEditModal && (
        <IdeaShareControlModal
          ideaDetails={{
            ideadId: ideaDetail?.id,
            share: ideaDetail?.share,
            detailLevel: ideaDetail?.ideadetail,
          }}
          hanldleModalOpen={showShareEditModal}
          handleShareModification={handleShareUpdate}
          handleModalClose={() => {
            setShowShareEditModal(false);
          }}
        />
      )}
    </>
  );
};
export default IdeaDetailHeader;
