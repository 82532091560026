import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  dashboardMenuData: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    loading: false,
  },
};

export const dashboardMenuSlice = createSlice({
  name: "dashboardMenuData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.dashboardMenuData.loading = action.payload;
    },
    setDashboardMenuData: (state, action) => {
      state.dashboardMenuData.items = action.payload;
    },
    setDashboardMenuDataError: (state, action) => {
      state.dashboardMenuData.error = action.payload;
      state.dashboardMenuData.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setDashboardMenuData, setDashboardMenuDataError } =
  dashboardMenuSlice.actions;

export default dashboardMenuSlice.reducer;

export const dashboardMenuDataSelector = (state) => state.dashboardMenuData;