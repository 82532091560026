import React, { useEffect, useState } from 'react';
import { useAuth } from '../Context/AuthProvider';
import AllRoutes from './Routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../redux/login';
const AuthRoute = () => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const [isMaintenance, setIsMaintenance] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setUserDetails(user));
  }, [user,dispatch])

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
           // eslint-disable-next-line
         if ("1" === "1") {
        setIsMaintenance(true)
      } else {
        setIsMaintenance(false)
        if (isMaintenance) {
                    navigate('/dashboard', { replace: true })
        }
      }
    } else {
      setIsMaintenance(false)
      if (isMaintenance) {
               navigate('/dashboard', { replace: true })
      }
    }
  }, [isMaintenance,navigate]);

  return <div style={{ height: "100vh" }}>
    <AllRoutes user={user} isMaintenance={isMaintenance} />
  </div>
}

export default AuthRoute;  