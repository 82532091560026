import { Box, Typography, Card, CardMedia, Skeleton } from '@mui/material';
import sampleVideo from '../../../SampleContent/sampleVideo.mp4';
const Walkthrough = () => {
  return (
    <Box mt={2} ml={2} mr={2} pb={2}>
      <Typography component={'h3'} variant={'dashboardCompHeading3'} mb={3}>
        Walkthrough
      </Typography>
      {sampleVideo ? (
        <Card>
          <CardMedia component="video" image={sampleVideo} controls />
        </Card>
      ) : (
        <Skeleton variant="rectangular" height={200} className="width100 mt1" />
      )}
    </Box>
  );
};

export default Walkthrough;
