import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import { toast } from 'react-toastify';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { errorMessage } from '../lib/helper';
import {
  setDashboardMenuData,
  setDashboardMenuDataError,
  setLoading,
} from '../redux/dashboardMenu';

export const GetDashboardMenuData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.post(`${ApiConfig.dashboardDetails}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setDashboardMenuData(resp?.data?.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setDashboardMenuDataError({
          isError: true,
          message: errorMessage(e),
        })
      );
      dispatch(setLoading(false));
      if (e?.data?.status !== 401 && e?.data?.status !== 403)
        toast.error(errorMessage(e), 2500);
    }
  };
};
