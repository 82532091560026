import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const CustomRadioGroup = ({
  ariaLabel,
  radioGroupName,
  radioOptions,
  selectedValue,
  handleChange,
}) => {
  return (
    <RadioGroup
      aria-labelledby={ariaLabel}
      name={radioGroupName}
      value={selectedValue}
      onChange={handleChange}
    >
      {radioOptions.map((optionValue) => (
        <FormControlLabel
          key={optionValue.label}
          value={optionValue.value}
          control={<Radio />}
          label={optionValue.label}
        />
      ))}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
