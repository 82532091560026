import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  Box,
  Typography,
  TableHead,
  Rating,
  Divider,
  Tooltip,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { labels } from '../../data/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
const IdeasBlock2 = ({
  handleDragChange = () => {},
  rateIdeaHandler = () => {},
  topFiveIdeasData,
  considerableIdeasData,
  notQuiteRightIdeasData,
  isGuest = false,
}) => {
  return (
    <Box>
      <DragDropContext onDragEnd={handleDragChange}>
        <Box className="mt1">
          <Box className="tipBackgroundWarning borderTopLeftRound borderTopRightRound py05">
            <Typography
              component={'h2'}
              variant={'dashboardCompHeading4'}
              className="colorBlack textAlignCenter"
            >
              {labels.filter.topFiveTitle}
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '5%' }}>
                    <Box className="displayFlex alignItemsCenter">
                      {labels.filter.tableHeaders.rankTitle}
                      <Tooltip title={labels.filter.tooltips.rankTooltip}>
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ marginLeft: 1 }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>
                    <Box className="displayFlex alignItemsCenter">
                      <CampaignIcon className="mr05" />
                      {labels.filter.tableHeaders.shoutTitle}
                      <Tooltip title={labels.filter.tooltips.shoutTooltip}>
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ marginLeft: 1 }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: '70%' }}></TableCell>
                  <TableCell sx={{ width: '15%' }}>
                    {labels.filter.tableHeaders.fitGradeTitle}
                  </TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="topFive" direction="vertical">
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {topFiveIdeasData?.map((item, index) => (
                      <Draggable
                        key={item?.id.toString()}
                        index={index}
                        draggableId={item?.id.toString()}
                        isDragDisabled={isGuest}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TableCell sx={{ width: '5%' }}>
                              <Box className="displayFlex alignContentCenter alignItemsCenter">
                                <Typography
                                  variant="dashboardCompHeading5"
                                  className="mr05"
                                >
                                  {item?.topidea}
                                </Typography>
                                <div {...provided.dragHandleProps}>
                                  <DragIndicatorIcon />
                                </div>
                              </Box>
                              <Divider className="mt05" />
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <Rating
                                onChange={(event, newValue) => {
                                  rateIdeaHandler(
                                    item?.id,
                                    newValue,
                                    'topFive'
                                  );
                                }}
                                value={item?.rating}
                              />
                            </TableCell>
                            <TableCell sx={{ width: '70%' }}>
                              {item?.name}
                              {item?.locked === 'true' && (
                                <LockOutlinedIcon
                                  fontSize="small"
                                  className="ml1"
                                ></LockOutlinedIcon>
                              )}
                              {item?.likedislike === 1 && (
                                <ThumbUpOffAltIcon
                                  fontSize="small"
                                  className="ml1"
                                ></ThumbUpOffAltIcon>
                              )}
                              {item?.likedislike === 2 && (
                                <ThumbDownOffAltIcon
                                  fontSize="small"
                                  className="ml1"
                                ></ThumbDownOffAltIcon>
                              )}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'white',
                                      boxShadow:
                                        '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                      '& .MuiTooltip-arrow': {
                                        color: 'white',
                                        '&:before': {
                                          border: '1px solid #ddd',
                                        },
                                      },
                                    },
                                  },
                                }}
                                title={
                                  <Box
                                    sx={{
                                      bgcolor: 'white',
                                      color: 'black',
                                      p: 2,
                                      borderRadius: 1,
                                      boxShadow: 'none',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.para1}
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label1}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade1?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade1}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label2}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade2?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade2}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label3}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade3?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade3}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label4}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade4?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade4}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label5}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade5?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade5}
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                arrow
                              >
                                <Typography
                                  variant="tableCell"
                                  className={`${
                                    'grade_' +
                                    item?.overallGrade?.toUpperCase() +
                                    '_textColor'
                                  } fontWeight600 fontSize18`}
                                >
                                  {item?.overallGrade}...
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box className="textAlignCenter borderDashed py05 borderRadius4">
                          <CopyAllIcon />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </Box>
        <Box className="mt1">
          <Box className="tipBackgroundSuccess borderTopLeftRound borderTopRightRound py05">
            <Typography
              component={'h2'}
              variant={'dashboardCompHeading4'}
              className="colorBlack textAlignCenter"
            >
              {labels.filter.consideringTitle}
            </Typography>
          </Box>
          <TableContainer className="mt1">
            <Table>
              <Droppable droppableId="considering" direction="vertical">
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {considerableIdeasData?.map((item, index) => (
                      <Draggable
                        key={item?.id.toString()}
                        index={index}
                        draggableId={item?.id.toString()}
                        isDragDisabled={isGuest}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TableCell sx={{ width: '5%' }}>
                              <div {...provided.dragHandleProps}>
                                <DragIndicatorIcon />
                              </div>
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <Rating
                                onChange={(event, newValue) => {
                                  rateIdeaHandler(
                                    item?.id,
                                    newValue,
                                    'Considering'
                                  );
                                }}
                                value={item?.rating}
                              />
                            </TableCell>
                            <TableCell sx={{ width: '70%' }}>
                              {item?.name}
                              {item?.locked === 'true' && (
                                <LockOutlinedIcon
                                  fontSize="small"
                                  className="ml1"
                                ></LockOutlinedIcon>
                              )}
                              {item?.likedislike === 1 && (
                                <ThumbUpOffAltIcon
                                  fontSize="small"
                                  className="ml1"
                                ></ThumbUpOffAltIcon>
                              )}
                              {item?.likedislike === 2 && (
                                <ThumbDownOffAltIcon
                                  fontSize="small"
                                  className="ml1"
                                ></ThumbDownOffAltIcon>
                              )}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'white',
                                      boxShadow:
                                        '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                      '& .MuiTooltip-arrow': {
                                        color: 'white',
                                        '&:before': {
                                          border: '1px solid #ddd',
                                        },
                                      },
                                    },
                                  },
                                }}
                                title={
                                  <Box
                                    sx={{
                                      bgcolor: 'white',
                                      color: 'black',
                                      p: 2,
                                      borderRadius: 1,
                                      boxShadow: 'none',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.para1}
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label1}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade1?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade1}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label2}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade2?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade2}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label3}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade3?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade3}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label4}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade4?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade4}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label5}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade5?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade5}
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                arrow
                              >
                                <Typography
                                  variant="tableCell"
                                  className={`${
                                    'grade_' +
                                    item?.overallGrade?.toUpperCase() +
                                    '_textColor'
                                  } fontWeight600 fontSize18`}
                                >
                                  {item?.overallGrade}...
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box className="textAlignCenter borderDashed py05 borderRadius4">
                          <CopyAllIcon />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </Box>
        <Box className="mt1">
          <Box className="tipBackgroundPurple borderTopLeftRound borderTopRightRound py05">
            <Typography
              component={'h2'}
              variant={'dashboardCompHeading4'}
              className="colorBlack textAlignCenter"
            >
              {labels.filter.notRightTitle}
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <Droppable droppableId="notQuiteRight" direction="vertical">
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {notQuiteRightIdeasData?.map((item, index) => (
                      <Draggable
                        key={item?.id.toString()}
                        index={index}
                        draggableId={item?.id.toString()}
                        isDragDisabled={isGuest}
                      >
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <TableCell sx={{ width: '5%' }}>
                              <div {...provided.dragHandleProps}>
                                <DragIndicatorIcon />
                              </div>
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>
                              <Rating
                                onChange={(event, newValue) => {
                                  rateIdeaHandler(
                                    item?.id,
                                    newValue,
                                    'notQuiteRight'
                                  );
                                }}
                                value={item?.rating}
                              />
                            </TableCell>
                            <TableCell sx={{ width: '70%' }}>
                              {item?.name}
                              {item?.locked === 'true' && (
                                <LockOutlinedIcon
                                  fontSize="small"
                                  className="ml1"
                                ></LockOutlinedIcon>
                              )}
                              {item?.likedislike === 1 && (
                                <ThumbUpOffAltIcon
                                  fontSize="small"
                                  className="ml1"
                                ></ThumbUpOffAltIcon>
                              )}
                              {item?.likedislike === 2 && (
                                <ThumbDownOffAltIcon
                                  fontSize="small"
                                  className="ml1"
                                ></ThumbDownOffAltIcon>
                              )}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'white',
                                      boxShadow:
                                        '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                      '& .MuiTooltip-arrow': {
                                        color: 'white',
                                        '&:before': {
                                          border: '1px solid #ddd',
                                        },
                                      },
                                    },
                                  },
                                }}
                                title={
                                  <Box
                                    sx={{
                                      bgcolor: 'white',
                                      color: 'black',
                                      p: 2,
                                      borderRadius: 1,
                                      boxShadow: 'none',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.para1}
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label1}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade1?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade1}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label2}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade2?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade2}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label3}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade3?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade3}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label4}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade4?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade4}
                                      </span>
                                    </Typography>
                                    <Typography variant="body2">
                                      {labels.filter.gradingPanelLabels.label5}{' '}
                                      <span
                                        className={`${
                                          'grade_' +
                                          item?.grade5?.toUpperCase() +
                                          '_textColor'
                                        } fontWeight600 fontSize1rem`}
                                      >
                                        {item?.grade5}
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                arrow
                              >
                                <Typography
                                  variant="tableCell"
                                  className={`${
                                    'grade_' +
                                    item?.overallGrade?.toUpperCase() +
                                    '_textColor'
                                  } fontWeight600 fontSize18`}
                                >
                                  {item?.overallGrade}...
                                </Typography>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Box className="textAlignCenter borderDashed py05 borderRadius4">
                          <CopyAllIcon />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </Box>
      </DragDropContext>
    </Box>
  );
};
export default IdeasBlock2;
