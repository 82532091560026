import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import DownloadIcon from '@mui/icons-material/Download';
const BusinessPlanHeader = ({}) => {
  return (
    <Box className="displayFlex justifySpaceBetween alignItemsCenter">
      <Box>
        <Typography component={'h1'} variant="dashboardCompHeading">
          {labels.businessPlan.title}
        </Typography>
        <Typography mt={1} variant="paraText1">
          {labels.businessPlan.subTitle}
        </Typography>
      </Box>
      <Box className="textAlignRight">
        <IconButton className="iconButtonType2">
          <DownloadIcon fontSize="small" className="mr05" />
          {labels.businessPlan.exportBtnLabel}
        </IconButton>
        <Button className="displayBlock mt05" variant="textButton2">
          {labels.businessPlan.samplePlanLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default BusinessPlanHeader;
