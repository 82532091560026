import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { errorMessage } from '../lib/helper';

export const ResendOtpService = async (payload) => {
  if (payload !== undefined) {
    try {
      const response = await axios.post(`${ApiConfig.resendOtp}`, payload);
      if (
        response?.data?.status >= STATUS_200 &&
        response?.data?.status < STATUS_300
      ) {
        toast.success(response.data?.message + ' ' + response.data?.data, {
          autoClose: 3000,
        });
        return true;
      } else {
        toast.error(response?.data?.message);
        throw response;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2000,
      });
      return false;
    }
  }
};
