import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { labels } from '../../../data/constants';
import SyncIcon from '@mui/icons-material/Sync';
import QuickIdeasSkeleton from './QuickIdeasSkeleton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const QuickIdeas = ({ ideasData = false }) => {
  const [showSkeleton, setShowSkeleton] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (ideasData) {
      setShowSkeleton(false);
    }
  }, [ideasData]);
  return (
    <Box mt={2} ml={2} mr={2}>
      <Typography component={'h3'} variant={'dashboardCompHeading3'}>
        {labels.quickIdeas.heading}
      </Typography>
      {!showSkeleton ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '70%' }}>
                  <Typography variant="tableHeading">Idea</Typography>
                </TableCell>
                <TableCell sx={{ width: '15%' }} className="centeredCell">
                  <Typography variant="tableHeading">AI Fit Grade</Typography>
                </TableCell>
                <TableCell sx={{ width: '15%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ideasData.map((ideaOption, index) => {
                return (
                  <TableRow key={'ideaOption' + index}>
                    <TableCell sx={{ width: '70%' }}>
                      <Typography variant="tableCell">
                        {ideaOption?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ width: '15%' }}
                      className={`${
                        'grade_' +
                        ideaOption?.overallGrade?.toUpperCase() +
                        '_textColor'
                      } centeredCell`}
                    >
                      {ideaOption?.overallGrade ? (
                        ideaOption?.overallGrade
                      ) : (
                        <IconButton className="primaryColor1">
                          {' '}
                          <SyncIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell sx={{ width: '15%' }} align="right">
                      <Button
                        onClick={() => {
                          navigate(`/businessIdeas/${ideaOption?.id}`);
                        }}
                        variant="textButton1"
                      >
                        {labels.quickIdeas.detailButton}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <QuickIdeasSkeleton />
      )}
    </Box>
  );
};
export default QuickIdeas;
