import { Box, Divider, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import AccountCompleteModal from '../../Components/CommonComponents/accountCompleteModal/AccountComplete';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import BusinessPlanHeader from '../../Components/BusinessPlan/businessPlanHeader';
import IdeaSelectorComp from '../../Components/BusinessPlan/ideaSelectorComp';
import { getBusinessIdeasService } from '../../Services/businessIdeasService';
import {
  bnp_saveAnswerService,
  getIdeaDetailsService,
} from '../../Services/businessPlanServices';
import TabWithScore from '../../Components/CommonComponents/TabWithScore/TabWithScore';
import ProfileSkeleton from '../../Components/Profile/ProfileSkeleton';
import ProfileTabSkeleton from '../../Components/Profile/ProfileTabSkeleton';
import IdeaTabContent from '../../Components/BusinessPlan/ideaTabContent';
import { aiAutofillService } from '../../Services/ideaDetailService';
const BusinessPlanComp = () => {
  const id = useRef('');
  const userRole = useSelector((state) => state?.userDetails?.role);
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );

  const [showAccountPrompt, setShowAccountPrompt] = useState(false);
  const [ideaList, setIdeaList] = useState([]);
  const [ideaOptions, setIdeaOptions] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [tabsCopy, setTabsCopy] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeIdea, setActiveIdea] = useState(null);
  useEffect(() => {
    if (userRole) {
      setShowAccountPrompt(false);
    }
  }, [userRole]);
  useEffect(() => {
    setTabsCopy([]);
    fetchIdeaList();
  }, [userWorkspace]);

  const fetchIdeaList = async () => {
    try {
      if (!userWorkspace?.id) {
        return;
      }
      const payload = {
        workspaceId: +userWorkspace.id,
      };
      const response = await getBusinessIdeasService(payload);
      if (response) {
        let ideaOptionList = [];
        response?.forEach((ideaOption) => {
          let idea = {
            label: ideaOption?.name,
            value: ideaOption?.id,
          };
          ideaOptionList.push(idea);
        });
        setIdeaOptions(ideaOptionList);
        setIdeaList(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchIdeaDetails = async (receivedIdeaId) => {
    id.current = receivedIdeaId;
    try {
      let payload = {
        workspaceid: userWorkspace?.id,
        ideaid: receivedIdeaId,
      };
      setShowLoader(true);
      const response = await getIdeaDetailsService(payload);
      if (!response && response?.length === 0) {
        return;
      }
      let tabData = [];
      response?.forEach((tabItem) => {
        let answeredQuestion = 0;
        for (let i = 0; i < tabItem.questions.length; i++) {
          if (tabItem.questions[i].answer !== null) {
            if (tabItem.questions[i]?.type === 'multiSelect') {
              let answerStringInArray = tabItem.questions[i].answer.split(',');
              let answerArray = [];
              answerStringInArray.forEach((item) => {
                if (item !== ' ') {
                  let answerLabel;
                  tabItem?.questions[i]?.options?.forEach((option) => {
                    if (option?.value === item) {
                      answerLabel = option?.label;
                    }
                  });
                  answerArray.push({ label: answerLabel, value: item });
                }
              });
              tabItem.questions[i].answer = answerArray;
              if (answerArray.length !== 0) {
                answeredQuestion++;
              }
            } else {
              if (tabItem.questions[i].answer !== ' ') {
                answeredQuestion++;
              }
            }
          }
          if (tabItem.questions[i]?.interDependentQuestion !== null) {
            tabItem.questions[i].isDependencyFullfilled = false;
          }
        }
        let tabDataItem = {
          ...tabItem,
          completionPercentage:
            (answeredQuestion / tabItem.questions.length) * 100,
        };
        tabData.push(tabDataItem);
      });
      let stringifiedData = JSON.stringify(tabData);
      setTabs(JSON.parse(stringifiedData));
      setTabsCopy(JSON.parse(stringifiedData));
      setActiveIdea(receivedIdeaId);
      setShowLoader(false);
    } catch (e) {
      console.log(e);
      setShowLoader(false);
    }
  };
  const tabChangeHandler = (event, tabInfo) => {
    setActiveTab(tabInfo);
  };
  const handleDataChange = (questionId, answerReceived) => {
    let tabData = [...tabsCopy];
    tabData.forEach((tabItem) => {
      tabItem.questions.forEach((questionItem) => {
        if (questionItem.id === questionId) {
          questionItem.answer = answerReceived;
        }
      });
    });
    isDependencyFulfilled(questionId, answerReceived, tabData);
  };
  const TabCompletionPercentage = (tabData) => {
    let tabDataCoppy = [];
    tabData?.forEach((tabItem) => {
      let answeredQuestion = 0;
      for (let i = 0; i < tabItem.questions.length; i++) {
        if (tabItem.questions[i].answer !== null) {
          if (tabItem.questions[i]?.type === 'multiSelect') {
            let answerStringInArray = tabItem.questions[i].answer.split(',');
            let answerArray = [];
            answerStringInArray.forEach((item) => {
              if (item !== ' ') {
                let answerLabel;
                tabItem?.questions[i]?.options?.forEach((option) => {
                  if (option?.value === item) {
                    answerLabel = option?.label;
                  }
                });
                answerArray.push({ label: answerLabel, value: item });
              }
            });
            tabItem.questions[i].answer = answerArray;
            if (answerArray.length !== 0) {
              answeredQuestion++;
            }
          } else {
            if (tabItem.questions[i].answer !== ' ') {
              answeredQuestion++;
            }
          }
        }
      }
      let tabDataItem = {
        ...tabItem,
        completionPercentage:
          (answeredQuestion / tabItem.questions.length) * 100,
      };
      tabDataCoppy.push(tabDataItem);
      setTabsCopy([...tabDataCoppy]);
    });
  };

  const isDependencyFulfilled = (questionId, answerReceived, tabData) => {
    if (!answerReceived) {
      TabCompletionPercentage(tabData);
    }
    tabData.forEach((tabItem) => {
      tabItem.questions.forEach((question) => {
        if (
          question.interDependentQuestion !== null &&
          question.interDependentQuestion === questionId
        ) {
          question.isDependencyFullfilled = true;
        }
      });
    });
    TabCompletionPercentage(tabData);
  };
  const checkAnswerChange = (questionId, answer) => {
    let isAnswerChanged;
    //looping through each tab.
    tabs.forEach((tabItem) => {
      tabItem.questions.forEach((questionItem) => {
        if (questionItem.id === questionId) {
          if (Array.isArray(answer)) {
            //Stringifying it to compare
            if (
              JSON.stringify(questionItem.answer) === JSON.stringify(answer)
            ) {
              isAnswerChanged = false;
              return;
            } else {
              isAnswerChanged = true;
              return;
            }
          } else {
            if (questionItem.answer === answer) {
              isAnswerChanged = false;
              return;
            } else {
              isAnswerChanged = true;
              return;
            }
          }
        }
      });
    });
    return isAnswerChanged;
  };
  const calculateOverallScore = () => {
    let overAllscore = 0;
    let tabsCompletionScore = 0;
    let tabsLength = tabsCopy.length;
    tabsCopy.forEach((tab) => {
      tabsCompletionScore += tab.completionPercentage;
    });
    overAllscore = Math.round(tabsCompletionScore / tabsLength);
    return overAllscore;
  };
  const submitAnswer = async (questionId, answer) => {
    try {
      let isAnswerChanged = checkAnswerChange(questionId, answer);
      if (isAnswerChanged !== true) {
        return;
      }
      let overallScore = calculateOverallScore();
      const payload = {
        questionId,
        answer: answer || ' ',
        workspaceId: userWorkspace?.id,
        ideaId: activeIdea,
        businessPlanScore: Math.round(overallScore),
      };

      const response = await bnp_saveAnswerService(payload);
      if (response) {
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initiateAiAutoFill = async (questionId, question) => {
    try {
      let payload = {
        ideaId: +id.current,
        autofill_field: question,
      };
      const response = await aiAutofillService(payload);
      if (response) {
        handleDataChange(questionId, response);
        submitAnswer(questionId, response);
        return response;
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <BusinessPlanHeader />
      <IdeaSelectorComp
        ideaOptions={ideaOptions}
        ideaList={ideaList}
        handleIdeaChange={fetchIdeaDetails}
      />

      <Divider sx={{ marginTop: 3 }} />
      {tabsCopy.length === 0 && !showLoader && (
        <Typography
          component={'h2'}
          mt={5}
          fontSize={24}
          fontWeight={'bold'}
          textAlign={'center'}
        >
          {labels.businessPlan.selectAnIdea}
        </Typography>
      )}
      {tabsCopy.length !== 0 && !showLoader && (
        <Box className="borderBottom2 borderColorGrey">
          <TabWithScore
            tabData={tabsCopy}
            handleTabChange={tabChangeHandler}
            selectedTab={activeTab}
          />
        </Box>
      )}
      {tabsCopy.length !== 0 && (
        <IdeaTabContent
          ideaId={activeIdea}
          tabQuestions={tabsCopy[activeTab]?.questions.sort(
            (a, b) => a?.sortby - b?.sortby
          )}
          tabDataHandler={handleDataChange}
          submitAnswer={submitAnswer}
          aiAutoFill={initiateAiAutoFill}
          isDisabled={
            userWorkspace?.role === 'guest'
              ? userWorkspace?.workspacePrivelege === 'commenter'
                ? true
                : false
              : false
          }
        />
      )}
      {showLoader && (
        <Box className="mt2">
          <ProfileTabSkeleton />
          <ProfileSkeleton />
        </Box>
      )}
      {!showAccountPrompt && (
        <AccountCompleteModal showDialog={showAccountPrompt} close={false} />
      )}
    </Box>
  );
};

export default BusinessPlanComp;
