import {
  Menu,
  MenuItem,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { labels } from '../../../data/constants';
import { toast } from 'react-toastify';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
const IdeaShareMenu = ({
  ideaDetail,
  anchorEl,
  handleClose = () => {},
  open,
  handleShareOpen = () => {},
}) => {
  const sharedURL = window.location.host + `/idea/${ideaDetail?.id}`;
  const copyToClipboard = () => {
    let itemToCopy = window.location.host + `/idea/${ideaDetail?.id}`;
    navigator.clipboard.writeText(itemToCopy).then(
      () => {
        toast.success('Link copied to clipboard');
      },
      () => {
        toast.error('Copying to clipboard failed.');
      }
    );
  };
  const mailIdeaDetails = () => {
    let ideaLink = window.location.host + `/idea/${ideaDetail?.id}`;
    let subject = 'Check out this business idea I made with Pathways';
    let messageBody = `Hi, %0D%0A %0D%0A I've been working on a business idea, ${ideaDetail?.name} Platform, and I want to share it with you. You can view more details about the idea at this link: ${ideaLink} %0D%0A %0D%0A I'd appreciate your feedback as I work towards launching this business. %0D%0A %0D%0A Thanks, %0D%0A %0D%0A[Your Name]`;

    window.location.href = `mailto:?subject=${subject}&body=${messageBody}`;
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      className="minWidth300"
    >
      <MenuItem className="customMenuItem">
        <FormControl disabled>
          <FormLabel id="idea-share-option">
            {labels.ideaDetails.shareIdeaLabel}
          </FormLabel>
          <RadioGroup
            aria-labelledby="idea-share-option"
            value={ideaDetail?.ideadetail}
            name="idea-share-radio-select"
          >
            <FormControlLabel
              value={"summary"}
              control={<Radio />}
              label="Summary"
            />
            <FormControlLabel
              value={"detailed"}
              control={<Radio />}
              label="Detailed"
            />
          </RadioGroup>
        </FormControl>
      </MenuItem>
      <MenuItem className="customMenuItem" onClick={handleShareOpen}>
        <EditIcon fontSize="small" className="mr10" />
        {labels.ideaDetails.disableModifyLabel}
      </MenuItem>
      <MenuItem
        onClick={copyToClipboard}
        disabled={ideaDetail?.share === 'false' ? true : false}
        className="customMenuItem"
      >
        <LinkIcon fontSize="small" className="mr10" />
        {labels.ideaDetails.copyLinkLabel}
      </MenuItem>
      <MenuItem
        onClick={mailIdeaDetails}
        disabled={ideaDetail?.share === 'false' ? true : false}
        className="customMenuItem"
      >
        <EmailIcon fontSize="small" className="mr10" />
        {labels.ideaDetails.emailLabel}
      </MenuItem>
      <MenuItem
        disabled={ideaDetail?.share === "false" ? true : false}
        className="customMenuItem"
      >
        <TwitterShareButton
          url={sharedURL}
          title={ideaDetail?.name}
          className="displayFlex"
        >
          <XIcon fontSize="small" className="mr10" />
          {labels.ideaDetails.tweetLabel}
        </TwitterShareButton>
      </MenuItem>
      <MenuItem
        disabled={ideaDetail?.share === "false" ? true : false}
        className="customMenuItem"
      >
        <FacebookShareButton
          url={sharedURL}
          title={ideaDetail?.name}
          description={ideaDetail?.description}
          
          className="displayFlex"
        >
          <FacebookIcon fontSize="small" className="mr10" />
          {labels.ideaDetails.shareFacebook}
        </FacebookShareButton>
      </MenuItem>
      <MenuItem
        disabled={ideaDetail?.share === "false" ? true : false}
        className="customMenuItem"
      >
        <LinkedinShareButton
          url={sharedURL}
          title={ideaDetail?.name}
          summary={ideaDetail?.description}
          className="displayFlex"
        >
          <LinkedInIcon fontSize="small" className="mr10" />
          {labels.ideaDetails.shareLinkedIn}
        </LinkedinShareButton>
      </MenuItem>
    </Menu>
  );
};

export default IdeaShareMenu;
