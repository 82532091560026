import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { errorMessage } from '../lib/helper';
import { setLoading, setUserDetails } from '../redux/login';

export const OtpVerify = (payload,login) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const response = await axios.post(`${ApiConfig.otpVerify}`, payload);
        if (
          response?.data?.status >= STATUS_200 &&
          response?.data?.status < STATUS_300
        ) {
          toast.success(response.data?.message, {
            autoClose: 3000,
          });
          localStorage.setItem('token', response?.data?.data[0]?.accessToken);
          localStorage.setItem(
            'refreshAuth',
            response?.data?.data[0]?.refreshToken
          );
          let userDetails = response?.data?.data[0]?.user;
          userDetails['workspace'] = response?.data?.data[0]?.userWorkspace.find((space)=>space.isdefaultworkspace);
          localStorage.setItem("workspace",JSON.stringify(userDetails['workspace']));
          login(userDetails);
          dispatch(setLoading(false));
          dispatch(setUserDetails(userDetails));
          return userDetails;
        } else {
          if(response?.data?.status !==400)
             toast.error(response?.data?.message);
          throw response;
        }
      } catch (e) {
                     toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
        return false;
      }
    };
  }
};
