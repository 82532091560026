import { Box, Grid, Skeleton } from "@mui/material";

const WelcomeSkeleton = () => {
  return (
    <Box className="mt1 ml1 mr1">
      <Grid
        className="mt05 displayFlex justifyCenter"
        container
        columnSpacing={2}
      >
        <Grid item xs={5}>
          <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} className="mt1"/>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" className="fontSize1rem mt3 width80" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="text" className="fontSize1rem mt1 width100" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" className="fontSize1rem mt1 width80" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="text" className="fontSize1rem mt1 width100" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="text" className="fontSize1rem mt1 width80" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="text" className="fontSize1rem mt1 width100" />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
      </Grid>
      <Grid container className="mt05" columnSpacing={2}>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"100%"} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default WelcomeSkeleton;
