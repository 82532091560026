import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import { setUserDetails } from '../../redux/login';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Grid, Typography } from '@mui/material';
import OtpForm from '../../Components/LoginAndSignup/OtpForm';
import EmailForm from '../../Components/LoginAndSignup/EmailForm';
import { LoginUser } from '../../Services/LoginService';

const LoginForm = () => {
  //To show or hide otp panel.
  const [showOtpPanel, setShowOtpPanel] = useState(false);
  const [loginData, setLoginData] = useState({ emailAddress: '', otp: '' });
  const [disableButton, setDisableButton] = useState(false);
  const [counter, setCounter] = useState(3);
  const dispatch = useDispatch();
  const { user } = useAuth();
  //To verify provided email and request for otp.
  const verifyEmailAndGetOtp = async (values) => {
    try {
      const mainValues = {
        email: values?.emailAddress.toLowerCase(),
      };
      setDisableButton(true);
      let response = await dispatch(LoginUser(mainValues));
      if (response === true) {
               setLoginData((prevState) => ({
          ...prevState,
          emailAddress: values.emailAddress,
        }));
        setShowOtpPanel(true);
      }
    } catch (e) {
      console.log(e);
    }
    setDisableButton(true);
  };
  React.useEffect(() => {
    if (disableButton) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      if (counter === 0) {
        setDisableButton(false);
        setCounter(3)
      }
    }
  }, [counter, disableButton]);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
        dispatch(setUserDetails(user))
        navigate('/dashboard', { replace: true });
           }
}, [user, dispatch, navigate]);
  //To hide otp panel and reset email form.
  const resetAndHide = () => {
    setLoginData({ emailAddress: '', otp: '' });
    setShowOtpPanel(false);
  };

  //To request and manage for another OTP.

  return (
    <>
      <div className="main-login-page ">
        <Container fluid className="login-div">
          <Grid container justifyContent={'center'}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              justifyContent={'center'}
              mt={7}
              px={4}
            >
              {!showOtpPanel && (
                <EmailForm
                  formTitle="Login"
                  submitHandler={verifyEmailAndGetOtp}
                  initialVal={loginData.emailAddress.toLowerCase()}
                  actionType={'login'}
                  disableStatus={disableButton}
                />
              )}
              {showOtpPanel && (
                <OtpForm loginData={loginData} onBack={resetAndHide} />
              )}
              <Typography
                mt={3}
                color={'gray'}
                fontSize={14}
                textAlign={'center'}
              >
                By logging in, I agree to the Pathways Terms of Service.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default LoginForm;
