import { Box, Skeleton } from '@mui/material';

const SetupStatusSkeleton = () => {
  return (
    <Box className="mt1">
      <Skeleton variant="text" className="fontSize1rem mt1 width100" />
      <Skeleton variant="text" className="fontSize1rem mt1 width100" />
      <Skeleton variant="text" className="fontSize1rem mt1 width100" />
      <Skeleton variant="text" className="fontSize1rem mt1 width100" />
      <Skeleton variant="text" className="fontSize1rem mt1 width100" />
      <Skeleton variant="text" className="fontSize1rem mt1 width100" />
    </Box>
  );
};
export default SetupStatusSkeleton;
