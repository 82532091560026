import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const CustomDialog = ({
  handleClose = () => {},
  handleOpen = () => {},
  dialogTitle = '',
  showClose = true,
  headingVariant = 'dashboardCompHeading2',
  width,
  ...props
}) => {
  return (
    <Dialog
      maxWidth={width}
      fullWidth={true}
      onClose={handleClose}
      open={handleOpen}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        pt={2}
        px={2}
      >
        {dialogTitle && (
          <DialogTitle
            sx={{ paddingTop: 0, paddingBottom: 0 }}
            variant={headingVariant}
          >
            {dialogTitle}
          </DialogTitle>
        )}

        {showClose && (
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <DialogContent>
        <Box>{props.children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
