import { Box } from '@mui/material';
import Navigation from '../../MainLayout/Navigation/Navigation';
import styles from './sidebar.module.css';
const Sidebar = () => {
  return (
    <Box className={styles.sidebarContainer}>
      <Navigation />
    </Box>
  );
};
export default Sidebar;
