import { Box, Typography, Button } from '@mui/material';
import CustomCircularProgress from '../../Custom Components/CustomCircularProgress/CustomCircularProgress';
import {
  labels,
  profileScore_25,
  profileScore_50,
  profileScore_75,
} from '../../../data/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileStatusSkeleton from './ProfileStatusSkeleton';
const ProfileStatus = ({ workspaceScore }) => {
  const navigate = useNavigate();
  const [profileCompletionScore, setProfileCompletionScore] = useState(null);
  useEffect(() => {
    setProfileCompletionScore(workspaceScore || 0);
  }, [workspaceScore]);
  return (
    <Box mt={2} ml={2} mr={2}>
      <Typography component={'h3'} variant={'dashboardCompHeading3'}>
        {labels.profileStatus.heading}
      </Typography>
      {profileCompletionScore !== null ? (
        <Box component={'div'} display={'flex'} alignItems={'center'} mt={3}>
          <Box component={'div'} mr={3}>
            <CustomCircularProgress value={profileCompletionScore} />
          </Box>
          <Box component={'div'}>
            <Typography>
              {profileCompletionScore <= profileScore_25
                ? labels.profileStatus.content1
                : profileCompletionScore > profileScore_25 &&
                  profileCompletionScore <= profileScore_50
                ? labels.profileStatus.content2
                : profileCompletionScore > profileScore_50 &&
                  profileCompletionScore <= profileScore_75
                ? labels.profileStatus.content3
                : labels.profileStatus.content4}
              <Button
                variant="textButton1"
                onClick={() => {
                  navigate('/profile');
                }}
              >
                {labels.profileStatus.editButton}
              </Button>
            </Typography>
          </Box>
        </Box>
      ) : (
        <ProfileStatusSkeleton />
      )}
    </Box>
  );
};
export default ProfileStatus;
