import DashboardLayout from '../Layouts/DashboardLayout';
import BusinessIdeasComp from './BusinessIdeasComp';

const BusinessIdeasContainer = () => {
  return (
    <DashboardLayout>
      <BusinessIdeasComp />
    </DashboardLayout>
  );
};
export default BusinessIdeasContainer;
