import { Box, Button, TextField, Typography } from '@mui/material';
import { dislikeValue, labels } from '../../data/constants';
import CustomDialog from '../Custom Components/CustomDialog/CustomDialog';
import { useState } from 'react';

const FeedbackModal = ({
  feedbackType,
  ideaDetail,
  ideaLockStatus,
  showModal = false,
  handleFeedbackSubmit = () => {},
  handleClose = () => {},
}) => {
  const [feebackValue, setFeedbackValue] = useState('');
  return (
    <CustomDialog
      width={'xs'}
      showClose={true}
      handleClose={handleClose}
      handleOpen={showModal}
      dialogTitle={labels.businessIdeas.feedbackModal.title}
    >
      <Box>
        <TextField
          className="textFieldType4 mt0"
          multiline
          minRows={3}
          maxRows={3}
          fullWidth
          onChange={(e) => {
            setFeedbackValue(e.target.value);
          }}
          placeholder={
            feedbackType === dislikeValue
              ? labels.businessIdeas.feedbackModal.dislikePlaceholder
              : labels.businessIdeas.feedbackModal.likePlaceholder
          }
        />

        {feedbackType === dislikeValue && ideaLockStatus === 'false' ? (
          <>
            <Typography variant="dashboardCompHeading5" className="mt1">
              {labels.businessIdeas.feedbackModal.noteTitle}
            </Typography>
            <Typography variant="paraText1" className="mt05">
              {labels.businessIdeas.feedbackModal.noteText}
            </Typography>
          </>
        ) : (
          ''
        )}
        {feedbackType === dislikeValue && ideaLockStatus === 'true' ? (
          <>
            <Typography color={'warning'} variant="dashboardCompHeading5" className="mt1">
              {labels.businessIdeas.feedbackModal.noteTitle}
            </Typography>
            <Typography variant="paraText1" className="mt05">
              {labels.businessIdeas.feedbackModal.NoteLockText}
            </Typography>
          </>
        ) : (
          ''
        )}
        <Box className="displayFlex justifyCenter alignItemsCenter mt1 textAlignCenter">
          <Button
            variant="buttonVariant1"
            onClick={() => {
              handleFeedbackSubmit(feedbackType, ideaDetail, feebackValue);
            }}
          >
            {labels.businessIdeas.feedbackModal.submitBtnLabel}
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
};
export default FeedbackModal;
