import { Grid } from '@mui/material';
import QuickIdeas from '../../Components/Dashboard/QuickIdeas/quickIdeas';
import SetupStatus from '../../Components/Dashboard/SetupStatus/SetupStatus';
import ProfileStatus from '../../Components/Dashboard/ProfileStatus/ProfileStatus';
import Walkthrough from '../../Components/Dashboard/Walkthrough/Walkthrough';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { dashboardMenuDataSelector } from '../../redux/dashboardMenu';
import { GetDashboardMenuData } from '../../Services/DashboardService';
import {
  getBusinessIdeasService,
  getFilteredIdeas,
} from '../../Services/businessIdeasService';
import { getUserDetailService } from '../../Services/AccountServices';
import WelcomeSkeleton from '../Welcome/WelcomeSkeleton';
import Welcome from '../Welcome/Welcome';

function Dashboard() {
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const userAllWorkspaces = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.allWorkspaces
  );
  const { dashboardMenuData } = useSelector(dashboardMenuDataSelector);
  const dispatch = useDispatch();
  const [userIdeas, setUserIdeas] = useState([]);
  const [hasRole, setHasRole] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [workspaceProfileScore, setWorkspaceProfileScore] = useState(null);

  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    if (userWorkspace?.id) {
      getfilterIdeas();
      getDashboardMenu();
      getUserDetails();
    }
  }, [userWorkspace]);
  useEffect(() => {
    if (userAllWorkspaces) {
      getWorkspaceProfileScore();
    }
  }, [userWorkspace, userAllWorkspaces]);

  const getWorkspaceProfileScore = () => {
    let profileScore;
    userAllWorkspaces.forEach((workspaceOption) => {
      if (workspaceOption?.id === userWorkspace?.id) {
        profileScore = workspaceOption?.profileScore;
      }
    });
    setWorkspaceProfileScore(profileScore);
  };

  const getUserDetails = async () => {
    const response = await getUserDetailService(dispatch);
    if (response) {
      setUserDetails(response);
      setHasRole(response.role || 0);
      setShowSkeleton(false);
    }
  };

  const getDashboardMenu = async () => {
    const payload = {
      workspaceid: userWorkspace?.id,
    };
    await dispatch(GetDashboardMenuData(payload));
  };
  const getUserIdeas = async () => {
    const payload = {
      workspaceId: +userWorkspace?.id,
    };
    const response = await getBusinessIdeasService(payload);
    if (response && response.length !== 0) {
      let responseCopy = response;
      responseCopy.length = 5;
      setUserIdeas(responseCopy);
    } else {
      setUserIdeas([]);
    }
  };
  const getfilterIdeas = async () => {
    const payload = {
      workspace: +userWorkspace?.id,
    };
    const resp = await getFilteredIdeas(payload);
    if (resp?.top5 || resp?.top5?.length !== 0) {
      setUserIdeas(resp?.top5);
    }
  };
  return (
    <>
      {showSkeleton && <WelcomeSkeleton />}
      {userIdeas?.length === 0 && !showSkeleton && (
        <>
          <Grid container className="displayFlex justifyCenter textAlignCenter">
            <Grid item xs={12}>
              <Welcome />
            </Grid>
            <Grid item xs={12}>
              <SetupStatus
                dashboardItems={dashboardMenuData?.items}
                hasRole={hasRole}
              />
            </Grid>
            <Grid item xs={7}>
              <Walkthrough />
            </Grid>
          </Grid>
        </>
      )}
      {userIdeas?.length > 0 && !showSkeleton && (
        <Grid container>
          <Grid item xs={12} md={7}>
            <QuickIdeas
              ideasData={
                userIdeas?.length &&
                userIdeas?.sort((a, b) => a?.topidea - b?.topidea)
              }
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SetupStatus
              dashboardItems={dashboardMenuData?.items}
              hasRole={hasRole}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <ProfileStatus workspaceScore={workspaceProfileScore} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Walkthrough />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;
