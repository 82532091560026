import { Box, Divider, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import ConstructionIcon from '@mui/icons-material/Construction';
const ResearchComp = () => {
  return (
    <Box mt={2} ml={2} mr={2} pb={3}>
      <Typography ml={2} component={'h1'} variant="dashboardCompHeading">
        {labels.research.title}
      </Typography>
      <Divider sx={{ marginTop: 3 }} />
      <Typography
        component={'h2'}
        mt={5}
        fontSize={24}
        fontWeight={'bold'}
        textAlign={'center'}
      >
        <ConstructionIcon /> This page is currently under development.
      </Typography>
    </Box>
  );
};

export default ResearchComp;
