import { Grid, Skeleton } from '@mui/material';

const IdeaDetailsSkeleton = () => {
  return (
    <Grid container className='mt1'>
      <Grid item xs={12} lg={6}>
        <Skeleton variant="text" className="width50 fontSize1rem" />
        <Skeleton variant="rectangular" height={30} className="mt05" />
      </Grid>
      <Grid item xs={12} className='mt1 mb1'>
        <Skeleton variant="text" className="width50 fontSize1rem" />
        <Skeleton variant="rectangular" height={60} className="mt05 width100" />
      </Grid>
    </Grid>
  );
};

export default IdeaDetailsSkeleton;
