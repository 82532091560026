import { useState } from 'react';
import { labels } from '../../../data/constants';
import CustomDialog from '../../Custom Components/CustomDialog/CustomDialog';
import {
  Typography,
  Box,
  Button,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
const IdeaShareControlModal = ({
  ideaDetails,
  handleModalClose = () => {},
  hanldleModalOpen,
  handleShareModification = () => {},
}) => {
  const [ideaShareLevel, setIdeaShareLevel] = useState(ideaDetails?.detailLevel);
  const [isShareLevelChanged, setIsShareLevelChanged] = useState(false);

  const handleShareLevelChange = (levelValue) => {
    if (levelValue !== ideaDetails?.detailLevel) {
      setIsShareLevelChanged(true);
      setIdeaShareLevel(levelValue);
    } else {
      setIsShareLevelChanged(false);
      setIdeaShareLevel(levelValue);
    }
  };
  return (
    <CustomDialog
      dialogTitle={labels.ideaDetails.shareModal.title}
      showClose={false}
      handleClose={handleModalClose}
      handleOpen={hanldleModalOpen}
    >
      <Typography variant="dashboardCompHeading5">
        {ideaDetails?.share === 'true'
          ? labels.ideaDetails.shareModal.disableShareLabel
          : labels.ideaDetails.shareModal.enableShareLabel}
      </Typography>
      <Typography className="mt05">
        {ideaDetails?.share === 'true'
          ? labels.ideaDetails.shareModal.warning
          : ' '}
      </Typography>
      <Button
        className="mt1 width100 textTransformNone"
        variant="buttonVariant1"
        fullWidth
        onClick={() => {
          let shareStatus = ideaDetails?.share === 'true' ? 'false' : 'true';
          handleShareModification(
            ideaDetails?.ideadId,
            shareStatus,
            ideaShareLevel || ideaDetails?.detailLevel
          );
        }}
      >
        {ideaDetails?.share === 'true'
          ? labels.ideaDetails.shareModal.disableShareLabel
          : labels.ideaDetails.shareModal.enableShareLabel}
      </Button>
      <Divider className="mt1" />
      <Typography className="mt1">
        {labels.ideaDetails.shareModal.changeShareLevelLabel}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={ideaShareLevel}
          name="radio-buttons-group"
          onChange={(e) => {
            handleShareLevelChange(e.target.value);
          }}
        >
          <FormControlLabel
            value={'summary'}
            control={<Radio />}
            label={labels.ideaDetails.shareModal.ideaSummaryLabel}
          />
          <FormControlLabel
            value={'detailed'}
            control={<Radio />}
            label={labels.ideaDetails.shareModal.ideaDetailedLabel}
          />
        </RadioGroup>
      </FormControl>
      <Box className="displayFlex justifySpaceBetween mt1_5">
        <Button
          onClick={handleModalClose}
          className="textTransformNone"
          variant="buttonVariant1"
        >
          {labels.ideaDetails.shareModal.cancelBtnLabel}
        </Button>
        <Button
          onClick={() => {
            handleShareModification(
              ideaDetails?.ideadId,
              ideaDetails?.share,
              ideaShareLevel
            );
          }}
          disabled={!isShareLevelChanged}
          className="textTransformNone"
          variant="buttonVariant1"
        >
          {labels.ideaDetails.shareModal.updateBtnLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default IdeaShareControlModal;
