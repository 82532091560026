import { Box, Typography, Button } from '@mui/material';
import OTPInput from 'react-otp-input';
import { useEffect, useState } from 'react';
import { errors } from '../../data/constants';
import { OtpVerify } from '../../Services/OtpVerify';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CountdownTimer from '../Custom Components/CountdownTimer/CountdownTimer';
import { ResendOtpService } from '../../Services/resendOtp';
import { useAuth } from '../../Context/AuthProvider';
const OtpForm = ({ onBack = () => {}, loginData }) => {
  const [otpData, setOtpData] = useState('');
  const [resendOtpStatus, setResendOtpStatus] = useState(true);
  const [enableDisable, setEnableDisable] = useState(false);
  const [timerData, setTimerData] = useState(5);
 const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      setResendOtpStatus(false);
    }, 300000);
  }, []);
  const [error, setError] = useState({
    showError: false,
    errorMessage: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useAuth();

  const otpVerifyHandler = async () => {
    try {
      const mainValues = {
        email: loginData?.emailAddress.toLowerCase(),
        otp: otpData.toString(),
      };
      const response = await dispatch(OtpVerify(mainValues, login));
      if (response) {
        if (response?.role) {
          if(location.state?.from?.pathname && location.state?.from?.search){
            navigate(`${location.state?.from?.pathname}${location.state?.from?.search}`);  
          }else{
            navigate('/dashboard');
          }
        } else {
          navigate('/account');
        }
      } else {
        setOtpData('');
        setEnableDisable(false);
        // setError({
        //   showError: true,
        //   errorMessage: errors.login.invalidOtp,
        // });
      }
    } catch (e) {
      console.log(e);
    }
  };
  //To handle and validate OTP form.

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    setEnableDisable(true);
    if (otpData === '') {
      setError({
        showError: true,
        errorMessage: errors.login.otpBlank,
      });
      setEnableDisable(false);
    } else if (otpData.length < 6) {
      setError({
        showError: true,
        errorMessage: errors.login.otpSize,
      });
      setEnableDisable(false);
    } else {
      setError({
        showError: false,
        errorMessage: '',
      });
      otpVerifyHandler();
    }
  };

  const handleBack = () => {
    setOtpData('');
    onBack();
  };

  const resendOtpHandler = async () => {
    try {
      const payload = {
        email: loginData.emailAddress.toLowerCase(),
      };
      const response = await ResendOtpService(payload);
      if (response) {
        setResendOtpStatus(true);
        setTimerData(5);
        setTimeout(() => {
          setResendOtpStatus(false);
        }, 300000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className="login-container"
      style={{
        backgroundColor: '#f3f3f3',
        borderRadius: '20px',
        padding: '40px 24px',
        margin: '0px 30px',
      }}
    >
      <Typography
        textAlign={'center'}
        color={'primary'}
        fontWeight={'bold'}
        fontSize={18}
      >
        Enter OTP
      </Typography>
      <Typography mt={3}>
        A one-time code has been sent to{' '}
        <Typography component={'span'} fontWeight={'bold'}>
          {loginData.emailAddress.toLowerCase()}
        </Typography>{' '}
        Enter the OTP here to log in.
      </Typography>

      <Box
        component={'form'}
        textAlign={'center'}
        noValidate
        onSubmit={handleOtpSubmit}
      >
        <Box display={'flex'} justifyContent={'center'} mt={3}>
          <OTPInput
            value={otpData}
            sx={{
              justifyContent: 'center',
            }}
            onChange={setOtpData}
            renderSeparator={<span> </span>}
            numInputs={6}
            inputType="tel"
            inputStyle={{
              width: '35px',
              height: '35px',
              padding: '0px',
              marginLeft: '4px',
              marginRight: '4px',
              fontSize: '24px',
              fontWeight: '400',
              lineHeight: '29px',
              borderRadius: '4px',
              border: '1px solid transparent',
              borderColor: error.showError ? 'red' : 'transparent',
              textAlign: 'center',
              backgroundColor: '#d7d7d7',
              MozAppearance: 'textfield',
            }}
            renderInput={(props) => <input {...props} />}
          />
        </Box>
        {error.showError && (
          <Typography mt color={'red'}>
            {error.errorMessage}
          </Typography>
        )}
        {timerData !== 0 && (
          <CountdownTimer
            timerValue={timerData}
            controlTimerValue={setTimerData}
            timerMessage="Resend OTP "
          />
        )}
        <Button
          sx={{ marginTop: 5 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={enableDisable}
        >
          Continue
        </Button>
        <Box
          component={'div'}
          mt={4}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Button onClick={handleBack}>Back</Button>
          <Button disabled={resendOtpStatus} onClick={resendOtpHandler}>
            Resend OTP
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default OtpForm;
