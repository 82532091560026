import { Box, Grid, Skeleton } from '@mui/material';
const IdeaDetailTabSkeleton = () => {
  return (
    <Box className="mt1">
      <Grid container columnSpacing={2} rowSpacing={2} className="mt1">
        <Grid item xs={12} md={6}>
          <Skeleton variant="text" width={'20%'} />
          <Skeleton
            variant="rectangle"
            className="mt05"
            width={'100%'}
            height={30}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="text" width={'20%'} />
          <Skeleton
            variant="rectangle"
            className="mt05"
            width={'100%'}
            height={30}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="text" width={'20%'} />
          <Skeleton
            variant="rectangle"
            className="mt05"
            width={'100%'}
            height={30}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="text" width={'20%'} />
          <Skeleton
            variant="rectangle"
            className="mt05"
            width={'100%'}
            height={30}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IdeaDetailTabSkeleton;
