import {
  Alert,
  Box,
  Grid,
  InputLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomSelect from '../Custom Components/CustomSelect/CustomSelect';
import { labels } from '../../data/constants';
import IdeaDetailTabSkeleton from './IdeaDetailTabSkeleton';
import { useState } from 'react';
import styles from './IdeaDetail.module.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TypingEffectTextField from './TypingEffectTextField';
const IdeaTabContent = ({
  handleBlur = () => {},
  handleChange = () => {},
  tabContent = [],
  aiAutoFill = () => {},
  tabType = {},
  isGuest = false,
}) => {
  const [questionLoader, setQuestionLoader] = useState({
    loading: false,
    forQuestion: null,
  });
  return (
    <Box className="mt05 mb2">
      {tabType?.tabName === labels.ideaDetails.tabs.goToMarket.tabLabel && (
        <Box>
          <Alert
            icon={<ErrorOutlineIcon fontSize="inherit" />}
            severity="success"
          >
            {labels.ideaDetails.tabs.goToMarket.suggestionTip.title}
            <ul className={styles.suggestionTipPoints}>
              <li>
                {
                  labels.ideaDetails.tabs.goToMarket.suggestionTip
                    .suggestionPoints.point1
                }
              </li>
              <li>
                {
                  labels.ideaDetails.tabs.goToMarket.suggestionTip
                    .suggestionPoints.point2
                }
              </li>
              <li>
                {
                  labels.ideaDetails.tabs.goToMarket.suggestionTip
                    .suggestionPoints.point3
                }
              </li>
            </ul>
          </Alert>
        </Box>
      )}
      {tabContent.length ? (
        <Grid container columnSpacing={4}>
          {tabContent.map((tabItem, index) => {
            if (tabItem.type === 'select' || tabItem.type === 'multiSelect') {
              return (
                <Grid
                  key={'tabContent' + index}
                  className="mt1"
                  item
                  xs={12}
                  md={6}
                >
                  <InputLabel
                    sx={{ overflow: 'visible' }}
                    shrink={false}
                    className="dashboardLabel1"
                  >
                    <Box className="displayFlex alignItemsCenter">
                      <Typography variant="customStyle1">
                        {tabItem?.question}
                      </Typography>
                      {tabItem?.question?.includes('System Type') && (
                        <Tooltip
                          title={
                            <Box>
                              <Typography variant="body2">
                                {
                                  labels.ideaDetails.systemTypeTooltipData
                                    .heading
                                }
                              </Typography>
                              <ul className="fontSize14">
                                <li>
                                  {
                                    labels.ideaDetails.systemTypeTooltipData
                                      .point1
                                  }
                                </li>
                                <li>
                                  {
                                    labels.ideaDetails.systemTypeTooltipData
                                      .point2
                                  }
                                </li>
                                <li>
                                  {
                                    labels.ideaDetails.systemTypeTooltipData
                                      .point3
                                  }
                                </li>
                              </ul>
                            </Box>
                          }
                          arrow
                        >
                          <ErrorOutlineIcon
                            className="ml05"
                            fontSize="inherit"
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </InputLabel>
                  <CustomSelect
                    selectedValue={tabItem?.answer || []}
                    handleChange={(value) => {
                      handleChange(tabItem.id, value);
                    }}
                    handleBlur={() => {
                      handleBlur(tabItem.id, tabItem?.answer);
                    }}
                    dropDownItems={tabItem.options}
                    multi={tabItem.type === 'multiSelect' ? true : false}
                    disabled={isGuest}
                  />
                </Grid>
              );
            }
            if (
              tabItem.type === 'textField' ||
              tabItem.type === 'textFieldMulti'
            ) {
              return (
                <Grid
                  className="mt1"
                  key={'tabConten' + tabType?.tabName + index}
                  item
                  xs={12}
                >
                  <TypingEffectTextField
                    tabItem={tabItem}
                    handleBlur={handleBlur}
                    aiAutoFill={aiAutoFill}
                    questionLoader={questionLoader}
                    setQuestionLoader={setQuestionLoader}
                    isDisabled={isGuest}
                  ></TypingEffectTextField>
                </Grid>
              );
            }
          })}
        </Grid>
      ) : (
        <IdeaDetailTabSkeleton />
      )}
    </Box>
  );
};
export default IdeaTabContent;
