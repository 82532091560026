import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const CountdownTimer = ({
  timerValue,
  timerMessage = '',
  controlTimerValue = () => {},
}) => {
  useEffect(() => {
    calculateTime();
  }, []);
  const [timer, setTimer] = useState({
    minutes: timerValue,
    seconds: 0,
  });
  const [timerStatus, setTimerStatus] = useState(true);
  const calculateTime = () => {
    let minutes = timer.minutes;
    let secondsLeft = timer.seconds;

    let countdownTimer = setInterval(() => {
      if (secondsLeft > 0) {
        secondsLeft--;
        setTimer((prevState) => {
          return {
            ...prevState,
            seconds: secondsLeft,
          };
        });
      } else {
        if (minutes > 0) {
          minutes--;
          secondsLeft = 59;
          setTimer(() => {
            return {
              minutes: minutes,
              seconds: secondsLeft,
            };
          });
        } else {
          clearInterval(countdownTimer);
          controlTimerValue(0);
          setTimerStatus(false);
        }
      }
    }, 1000);
  };

  return (
    <>
      {timerStatus ? (
        <Typography mt={2}>
          {timerMessage}: {timer.minutes}:{timer.seconds < 10 ? 0 : ''}
          {timer.seconds}
        </Typography>
      ) : (
        ''
      )}
    </>
  );
};

export default CountdownTimer;
