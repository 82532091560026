import DashboardLayout from '../Layouts/DashboardLayout';
import AccountComponent from './AccountComponent';

const AccountContainer = () => {
  return (
    <DashboardLayout>
      <AccountComponent />
    </DashboardLayout>
  );
};

export default AccountContainer;
