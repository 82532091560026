import { Box, Grid, Button, Typography } from '@mui/material';
import { labels } from '../../data/constants';
import CustomSelect from '../Custom Components/CustomSelect/CustomSelect';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const IdeaSelectorComp = ({
  ideaOptions = [],
  ideaList = [],
  handleIdeaChange = () => {},
}) => {
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleIdeaSelectChange = (value) => {
    let idea = ideaList.filter((ideaOpt) => ideaOpt?.id === value?.value);
    setSelectedOption(value);
    setSelectedIdea(idea[0]);
    handleIdeaChange(value?.value);
  };
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedOption(null);
    setSelectedIdea(null);
  }, [ideaOptions]);
  return (
    <Grid mt={1} p={2} container className="backgroundLightGrey borderRadius8">
      <Grid item xs={12} lg={6}>
        <Box className="displayFlex alignItemsCenter">
          <Typography className="mr1 mb0" variant="dashboardCompHeading5">
            {labels.businessPlan.ideaSelector.title}
          </Typography>
          <Box className="flex1">
            <CustomSelect
              selectedValue={selectedOption || []}
              handleChange={(value) => {
                handleIdeaSelectChange(value);
              }}
              dropDownItems={ideaOptions}
              multi={false}
              customStyles={{
                marginTop: '0px',
                minHeight: '40px',
                marginRight: '16px',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6} className="displayFlex alignItemsCenter">
        {selectedIdea ? (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="paraText1">
              {selectedIdea?.description}
              <Button
                variant="textButton2"
                onClick={() => {
                  navigate(`/businessIdeas/${selectedIdea?.id}`);
                }}
              >
                {labels.businessIdeas.businessIdeasContainer.detailButton}
              </Button>
            </Typography>
          </Box>
        ) : (
          <Typography variant="dashboardCompHeading5">
            {labels.businessPlan.ideaSelector.subTitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default IdeaSelectorComp;
