import { Navigate, Outlet, useLocation } from 'react-router-dom';
export const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children }) => {
  const location = useLocation();
  const from = {
    pathname: location.pathname,
    search: location.search,
  };
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace  state={{ from }}/>
  }
  return children ? children : <Outlet />;
};
