import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { labels } from "../../data/constants";
import CreateIcon from "@mui/icons-material/Create";
import { useEffect, useRef, useState } from "react";
import EditWorkspace from "../../Components/Workspace/EditWorkspace";
import DeleteWorkspace from "../../Components/Workspace/DeleteWorkspace";
import CreateWorkspace from "../../Components/Workspace/CreateWorkspace";
import { useSelector } from "react-redux";
import {
  deleteCollaborator,
  getCollaboratorsList,
  getUserDetailsFromWorkspace,
} from "../../Services/WorkspaceService";
import ExistingCollaborator from "../../Components/Workspace/Collaborator";
import InviteCollaborators from "../../Components/Workspace/InviteCollaborators";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

const WorkspaceComp = () => {
  const dividerSpacing = {
    marginTop: "30px",
    marginBottom: "22px",
  };

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [noneSelfOwner, setNoneSelfOwner] = useState(null);
  const handleEditWorkspace = () => setEditModalOpen(true);
  const isGuest = useRef(false);
  const userWorkspace = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.workspace
  );
  const userEmail = useSelector(
    (state) => state?.loginUserData?.user?.userDetails?.email
  );
  useEffect(() => {
    if (userWorkspace) {
      isGuest.current = userWorkspace?.role === "guest" ? true : false;
      getListCollab();
      if (userWorkspace?.role === "guest") {
        getUserDetails();
      } else {
        setNoneSelfOwner(null);
      }
    } else {
      setNoneSelfOwner(null);
    }
  }, [userWorkspace]);

  const getUserDetails = async () => {
    const payload = {
      workspaceId: userWorkspace?.id,
    };
    const response = await getUserDetailsFromWorkspace(payload);
    setNoneSelfOwner({
      name: `${response?.data?.first_name} ${response?.data?.last_name}`,
      email: response?.data?.email,
    });
  };

  const getListCollab = async () => {
    const response = await getCollaboratorsList({
      workspaceId: +userWorkspace?.id,
    });
    setCollaboratorsList(response?.data);
  };
  const onDeleteCollaborator = async (id) => {
    const payload = {
      collaboratorid: id,
    };
    await deleteCollaborator(payload);
    await getListCollab();
  };
  return (
    <Box className="mt1 ml1 mr1">
      <Box className="displayFlex alignItemsCenter" mb={3}>
        <Typography component={"h1"} variant="dashboardCompHeading">
          {userWorkspace?.name} {labels.workspace.title}
        </Typography>
        <IconButton
          className="ml1 iconButtonType4"
          onClick={handleEditWorkspace}
          disabled={isGuest.current}
        >
          <CreateIcon fontSize="small" />
          {labels.workspace.editButtonLabel}
        </IconButton>
      </Box>
      <Typography variant="dashboardCompHeading3">
        {labels.workspace.collaborator}
      </Typography>
      <Grid
        mb={3}
        xs={10}
        container
        sx={{ border: "1px solid #d0d0d0", "border-radius": "8px" }}
      >
        <InviteCollaborators
          userEmail={userEmail}
          userWorkspace={userWorkspace}
          getListCollab={getListCollab}
          isGuest={isGuest.current}
        ></InviteCollaborators>

        <Grid
          item
          xs={12}
          sx={{ padding: "13px" }}
          gap={1}
          className="displayFlex flexDirectionColumn"
        >
          <Typography variant="greyText">
            {labels.workspace.existingCollaborator}
          </Typography>
          {/* my profile */}
          <Box className="displayFlex justifySpaceBetween alignItemsCenter">
            <Box className="displayFlex alignItemsCenter">
              <AccountCircleTwoToneIcon
                fontSize="large"
                className="mr1"
                sx={{ color: "rgb(121, 209, 151)" }}
              />
              <Box>
                {!isGuest.current && (
                  <Typography variant="tableHeading">ME</Typography>
                )}
                {isGuest.current && (
                  <Typography variant="tableHeading">
                    {noneSelfOwner?.name}
                  </Typography>
                )}
                {!isGuest.current && <Typography>{userEmail}</Typography>}
                {isGuest.current && (
                  <Typography>{noneSelfOwner?.email}</Typography>
                )}
              </Box>
            </Box>
            <Box className="displayFlex">
              <Typography
                sx={{ color: "rgb(80, 80, 80)" }}
                className="fontSize12"
              >
                {labels.workspace.ownerLabel}
              </Typography>
            </Box>
          </Box>

          {/* collaborators profile */}
          {collaboratorsList?.map((collaborator, index) => (
            <Box key={index}>
              <ExistingCollaborator
                collaborator={collaborator}
                onDeleteCollaborator={onDeleteCollaborator}
                isGuest={isGuest.current}
                userEmail={userEmail}
              ></ExistingCollaborator>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Typography variant="dashboardCompHeading3">
        {labels.workspace.deleteButtonLabel}
      </Typography>
      <Typography>{labels.workspace.deleteNoteText}</Typography>
      {userWorkspace?.isdefaultworkspace && (
        <Typography className="displayBlock" variant="errorText">
          {labels.workspace.notDeleteMainWorkspace} {labels.supportEmailID}{" "}
          {"for account deletion."}
        </Typography>
      )}
      <Button
        className="mt1"
        variant="warningButton"
        disabled={userWorkspace?.isdefaultworkspace || isGuest.current}
        onClick={() => {
          setDeleteModalOpen(true);
        }}
      >
        {labels.workspace.deleteButtonLabel}
      </Button>
      <Divider sx={dividerSpacing} />
      <Typography variant="dashboardCompHeading3">
        {labels.workspace.createButtonLabel}
      </Typography>
      <Typography>{labels.workspace.createNoteText}</Typography>
      <Box className="displayFlex mt1 mb1">
        <Button
          variant="buttonVariant1"
          className="textTransformNone"
          onClick={() => {
            setCreateModalOpen(true);
          }}
        >
          {labels.workspace.createButtonLabel}
        </Button>
        <Typography variant="customStyle2" sx={{ height: "fit-content" }}>
          {labels.pro.title}
        </Typography>
      </Box>

      <EditWorkspace
        open={editModalOpen}
        setOpen={setEditModalOpen}
        userWorkspace={userWorkspace}
      ></EditWorkspace>
      <DeleteWorkspace
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        userWorkspace={userWorkspace}
      ></DeleteWorkspace>
      <CreateWorkspace
        open={createModalOpen}
        setOpen={setCreateModalOpen}
      ></CreateWorkspace>
    </Box>
  );
};
export default WorkspaceComp;
