import { useState, useEffect } from 'react';
import { Box, Button, FormHelperText, Typography } from '@mui/material';
import CustomDialog from '../../Components/Custom Components/CustomDialog/CustomDialog';
import { labels } from '../../data/constants';
import OTPInput from 'react-otp-input';
import { errors } from '../../data/constants';
import CountdownTimer from '../../Components/Custom Components/CountdownTimer/CountdownTimer';
import '../../Styles/commonStyles.css';
const AccountOtpModal = ({
  userEmail,
  otpVerifyHandler = () => {},
  handleCancel = () => {},
  resendOtpHandler = () => {},
  showModal = false,
}) => {
  const [enableDisable, setEnableDisable] = useState(false);
  const [otpData, setOtpData] = useState('');
  const [resendOtpStatus, setResendOtpStatus] = useState(true);
  const [timerData, setTimerData] = useState(5);
  useEffect(() => {
    setTimeout(() => {
      setResendOtpStatus(false);
    }, 300000);
  }, []);
  const [error, setError] = useState({
    showError: false,
    errorMessage: ' ',
  });
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setEnableDisable(true);
    if (otpData === '') {
      setError({
        showError: true,
        errorMessage: errors.login.otpBlank,
      });
      setEnableDisable(false);
    } else if (otpData.length < 6) {
      setError({
        showError: true,
        errorMessage: errors.login.otpSize,
      });
      setEnableDisable(false);
    } else {
      setError({
        showError: false,
        errorMessage: ' ',
      });
      const response = await otpVerifyHandler(otpData);
      if (!response) {
        setOtpData('');
        setEnableDisable(false);
      }
    }
  };
  const resendOtp = async () => {
    const response = await resendOtpHandler();
    if (response) {
      setResendOtpStatus(true);
      setTimerData(5);
      setTimeout(() => {
        setResendOtpStatus(false);
      }, 300000);
    }
  };
  return (
    <CustomDialog width={'xs'} handleOpen={showModal} showClose={false}>
      <Typography className="textCenter fontBold fontSize18">
        {labels.accounts.accountsOtpModal.otpHeading}
      </Typography>
      <Typography className="mt3 textCenter">
        {labels.accounts.accountsOtpModal.otpSentLabel}{' '}
        <Typography component={'span'} fontWeight={'bold'}>
          {userEmail.toLowerCase()}
        </Typography>{' '}
        {labels.accounts.accountsOtpModal.enterOtpLabel}
      </Typography>
      <Box
        component={'form'}
        className="textAlignCenter"
        noValidate
        onSubmit={handleOtpSubmit}
      >
        <FormHelperText className="textCenter mt1" error={error.showError}>
          {error.showError ? error.errorMessage : ' '}
        </FormHelperText>
        <Box className="displayFlex justifyCenter mt1">
          <OTPInput
            value={otpData}
            className="justifyContentCenter"
            onChange={(value) => {
              setOtpData(value);
              setError({ showError: false, errorMessage: ' ' });
            }}
            renderSeparator={<span> </span>}
            numInputs={6}
            inputType="tel"
            inputStyle={{
              width: '35px',
              height: '35px',
              padding: '0px',
              marginLeft: '4px',
              marginRight: '4px',
              fontSize: '24px',
              fontWeight: '400',
              lineHeight: '29px',
              borderRadius: '4px',
              border: '1px solid transparent',
              borderColor: error.showError ? 'red' : 'transparent',
              textAlign: 'center',
              backgroundColor: '#d7d7d7',
              MozAppearance: 'textfield',
            }}
            renderInput={(props) => <input {...props} />}
          />
        </Box>

        {timerData !== 0 ? (
          <CountdownTimer
            timerValue={timerData}
            controlTimerValue={setTimerData}
            timerMessage="Resend OTP "
          />
        ) : (
          <Box className='minHeight40'></Box>
        )}

        <Button
          className="displayBlock mt1 mlAuto mrAuto"
          variant="buttonVariant1"
          type="submit"
          disabled={enableDisable}
        >
          Continue
        </Button>
      </Box>
      <Box
        component={'div'}
        mt={4}
        display={'flex'}
        justifyContent={'space-between'}
        className="mt4 displayFlex displayFlex"
      >
        <Button variant="buttonVariant1" onClick={handleCancel}>
          {labels.accounts.accountsOtpModal.cancelBtnLabel}
        </Button>
        <Button
          onClick={resendOtp}
          variant="buttonVariant1"
          disabled={resendOtpStatus}
        >
          {labels.accounts.accountsOtpModal.resendBtnLabel}
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default AccountOtpModal;
