import { Skeleton } from '@mui/material';

const AccountSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" className="fontSize1rem width50" />
      <Skeleton variant="rectangular" height={30} className="width100 mt1" />
    </>
  );
};
export default AccountSkeleton;
