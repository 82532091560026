import {
  Box,
  TextField,
  Typography,
  Button,
  Link,
  FormHelperText,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { errors } from '../../data/constants';
const EmailForm = ({
  formTitle = '',
  submitHandler = () => {},
  initialVal,
  actionType = '',
  disableStatus,
}) => {
  const navigate = useNavigate();
  //To handle and validate email form.
  const emailFormFormik = useFormik({
    initialValues: {
      emailAddress: initialVal,
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string()
        .test('emailCheck', errors.login.validEmail, (value) => {
          let regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          if (regex.test(value)) {
            return true;
          } else {
            return false;
          }
        })
        .required(errors.login.emailRequired),
    }),
    onSubmit: submitHandler,
  });
  return (
    <div
      className="login-container"
      style={{
        backgroundColor: '#f3f3f3',
        borderRadius: '20px',
        padding: '40px 24px',
        margin: '0px 30px',
        minHeight: '370px',
      }}
    >
      <Typography
        textAlign={'center'}
        color={'primary'}
        fontWeight={'bold'}
        fontSize={24}
      >
        {formTitle}
      </Typography>

      <Box
        component={'form'}
        mt={3}
        textAlign={'center'}
        noValidate
        onSubmit={emailFormFormik.handleSubmit}
      >
        <TextField
          placeholder="Enter your email."
          name="emailAddress"
          id="emailAddress"
          type="email"
          sx={{
            borderRadius: '5px',
            marginTop: 6,
            padding: '0px',
          }}
          fullWidth
          inputProps={{
            sx: {
              padding: '0px 10px',
              minHeight: '40px',
              background: '#fff',
            },
          }}
          onChange={emailFormFormik.handleChange}
          value={emailFormFormik.values.emailAddress}
        />
        <FormHelperText
          error={
            emailFormFormik.touched.emailAddress &&
            Boolean(emailFormFormik.errors.emailAddress)
              ? true
              : false
          }
        >
          {emailFormFormik.touched.emailAddress &&
          emailFormFormik.errors.emailAddress
            ? emailFormFormik.errors.emailAddress
            : ' '}
        </FormHelperText>
        <Button
          sx={{ marginTop: 5 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={disableStatus}
        >
          Continue
        </Button>
        {actionType === 'signup' && (
          <Typography mt={6}>
            Already have an account?{' '}
            <Button
              onClick={() => {
                navigate('/login');
              }}
            >
              Login here
            </Button>
          </Typography>
        )}
        {actionType === 'login' && (
          <Typography mt={6}>
            Don't have an account?{' '}
            <Button
              onClick={() => {
                navigate('/signup');
              }}
            >
              Register here
            </Button>
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default EmailForm;
